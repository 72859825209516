export const CURRENT_CTN= 'numerocurrentctn';
export const CURRENT_EMP= 'nombrecurrentemp';
export const CURRENT_BKG= 'numerocurrentbooking';
export const CURRENT_PESADA= 'pesada';
export const CURRENT_NROPESADA= 'numerocurrentpesada';
export const DIAS_CONTENEDOR= 'cantdiasc';
export const  DIAS_BOOKING= 'cantdiasb';
export const CURRENT_PESADAS= 'cantdiasp';
export const DEFAULT_EMPRESA= 'nombreempresa'; 
export const ULTIMA_ETIQUETA= 'nroetiqueta'; 
export const TOKEN_NOTIFICATION= 'tokenregist'; 
export const DIAS_EVENTOSPESDA= 'cantdiasep';  
export const EMPRESAS_MENU= 'empmenu';
export const BOOKING_MENU= 'bm';
export const PESADAS_MENU= 'pm'; 
export const FOTOS_MENU= 'fm';
export const EP_MENU= 'epm';
export const CTN_MENU= 'ctnmenu';
export const PAGE_INICIO= 'pi';
export const INICIO_MENU= 'iniciomenu';
export const CONTROL_LOTE= 'booleanctnlote';
export const ESCANEO_CONTINUO= 'booleanesccontinuo';

export const EVENTOS_PESADAS = 'eventos.pesadas';
export const EVENTOS_LOTES = 'eventos.lotes';
