
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection, CollectionReference, DocumentReference, Query } from '@angular/fire/firestore';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Entity } from '../models';
import { Action, DocumentChangeAction, DocumentSnapshot } from '@angular/fire/firestore/interfaces';
import { Logger } from '../../core/log';
import { ErrorMessages } from '../error';
import WhereFilterOp = firebase.firestore.WhereFilterOp;

export class ApiError extends Error {
    error: any;
    // Custom Client side message.
    message: string;

    constructor(error: any, customMessage: string) {
        super();
        this.error = error;
        this.message = customMessage;
    }

}

export class AppError extends Error {
    message: string;

    constructor(public code: string, message?: string) {
        super();
        this.message = message;
    }
}

export interface WhereClause {
    fieldPath: string;
    opStr: WhereFilterOp;
    value: any;
}

export interface QueryConfig {
    field?: string; // field to orderBy
    limit?: number; // limit per query
    reverse?: boolean; // reverse order?
    prepend?: boolean; // prepend to source?
    lastValue?: any;
    ignoreLastValue?: boolean;
    dontListenChanges?: boolean;
    whereClauses?: Array<WhereClause>;
}

export interface PagedResult<T extends Entity> {
    values: Array<T>;
    queryConfig: QueryConfig;
}

export class RestService<T extends Entity> {

    protected collection: AngularFirestoreCollection<any>;

    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger,
        protected collectionName: string) {

        this.collection = store.collection<T>(collectionName);

    }

    add(entity: T): Observable<T> {
        return from(this.store.collection(this.collectionName)
            .add(entity))
            .pipe(
                catchError(err => throwError(this.mapError(err))),
                switchMap((doc: DocumentReference) => from(doc.get())),
                map((snap: DocumentSnapshot<any>) => this.mapDocument(snap))
            );
    }

    all(dontListenChanges?: boolean): Observable<Array<T>> {
        return this.collection
            .snapshotChanges()
            .pipe(
                dontListenChanges ? take(1) : tap(() => {
                }),
                catchError(err => throwError(this.mapError(err))),
                map(action => this.mapActionArray(action))
            );
    }

    /**
     * Metodo para paginado de firebase.
     * @param options - QueryOptions contiene el limite de elementos y el ultimo key
     * de la colleccion. Puede funcionar con paginado incremental (limit aumentando) o
     * con paginado normal (trayendo la informacion de a pedacitos). Para que el paginado
     * sea incremental, se debe setear la propiedad ignorelastValue en true.
     */
    paginated(options: QueryConfig): Observable<PagedResult<T>> {
        return from(this.store.collection(this.collectionName, ref => {
            let query = ref.orderBy(options.field, options.reverse ? 'desc' : 'asc');
            if (options.lastValue && !options.ignoreLastValue) {
                query = query.startAfter(options.lastValue);
            }
            if (options.limit) {
                query = query.limit(options.limit);
            }
            if (options.whereClauses && options.whereClauses.length > 0) {
                for (const clause of options.whereClauses) {
                    query = query.where(clause.fieldPath, clause.opStr, clause.value);
                }
            }

            return query;
        })
            .snapshotChanges())
            .pipe(
                // Si no se necesita escuchar cambios, take(1) mata la subscripcion una vez resuelta.
                options.dontListenChanges ? take(1) : tap(() => {
                }),
                catchError(err => throwError(this.mapError(err))),
                map(action => this.mapPagedResult(action, options))
            );
    }

    allWith(options: QueryConfig): Observable<Array<T>> {
        return from(this.store.collection(this.collectionName, ref => {
                let query: CollectionReference | Query = ref;
                if (options.whereClauses && options.whereClauses.length > 0) {
                    for (const clause of options.whereClauses) {
                        query = query.where(clause.fieldPath, clause.opStr, clause.value);
                    }
                }
                // Order by
                if (options.field) {
                    query = ref.orderBy(options.field, options.reverse ? 'desc' : 'asc');
                }
                // A partir de tal valor?
                if (options.lastValue) {
                    query = query.startAfter(options.lastValue);
                }
                // Limite de rows?
                if (query.limit) {
                    query = query.limit(options.limit);
                }

                return query;
            })
                .snapshotChanges()
                .pipe(
                    options.dontListenChanges ? take(1) : tap(() => {
                    }),
                    catchError(err => throwError(this.mapError(err))),
                    map(action => this.mapActionArray(action))
                )
        );
    }

    find(uid: string, dontListenChanges?: boolean): Observable<T> {
        return this.collection
            .doc(uid)
            .snapshotChanges()
            .pipe(
                dontListenChanges ? take(1) : tap(() => {
                }),
                catchError(err => throwError(this.mapError(err))),
                map(action => this.mapAction(action))
            );
    }

    first(queryConfig: QueryConfig): Observable<T> {
        return this.allWith(queryConfig)
            .pipe(
                map(data => {
                    return data.length > 0 ? data[0] : undefined;
                })
            );
    }

    set(entity: T): Observable<void> {
        const newEntity = shallowCopy(entity);
        const uid = entity.id;
        delete newEntity.id;

        return from(this.collection.doc(uid)
            .set(newEntity))
            .pipe(
                catchError(err => throwError(this.mapError(err)))
            );
    }

    update(entity: T): Observable<void> {
        const newEntity = shallowCopy(entity);
        const uid = entity.id;
        delete newEntity.id;

        return from(this.collection.doc(uid)
            .update(newEntity))
            .pipe(catchError(err => throwError(this.mapError(err))));
    }

    delete(uid: string): Observable<void> {
       console.log( this.collectionName)
        return from(
            this.collection.doc(uid).delete()
        ).pipe(
            catchError(err => throwError(this.mapError(err)))
        );
    }

    public switchToSubCollection(path: string): void {
        this.collection = this.store.collection(path);
        this.collectionName = path;
    }

    protected mapError(error: any): void {
        // Get error from errorMessages, if not found get the firebase error or a generic message if not defined.
        const message = error.code ? this.errorMessages.getMessage(error.code) : error.message || this.errorMessages.getGenericMessage();
        this.logger.error(error);
        throw new ApiError(error, message);
    }

    protected mapDocument(doc: DocumentSnapshot<any>): T {
        return {
            id: doc.id,
            ...doc.data()
        };
    }

    protected mapAction(action: Action<any>): T {
        return {
            id: action.payload.id,
            ... action.payload.data()
        };
    }

    protected mapActionArray(actions: Array<DocumentChangeAction<any>>): Array<T> {
        return actions.map(
            a => {
                return {
                    id: a.payload.doc.id,
                    ... a.payload.doc.data()
                };
            }
        );
    }

    protected mapPagedResult(actions: Array<DocumentChangeAction<any>>, lastQuery: QueryConfig): PagedResult<T> {
        const values = actions.map(
            a => {
                return {
                    id: a.payload.doc.id,
                    ... a.payload.doc.data()
                };
            });
        let lastValue;
        if (actions.length > 0) {
            lastValue = actions[actions.length - 1].payload.doc;
        }

        return {
            values,
            queryConfig: {
                ...lastQuery,
                lastValue
            }
        };
    }

}

function shallowCopy(obj: any): any {
    return {
        ...obj
    };
}
