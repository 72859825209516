//httpConfig.interceptor.ts
import {  HttpRequest,  HttpHandler,  HttpEvent,  HttpInterceptor,  HttpResponse,  HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  loaderToShow: any;
  constructor(
    public loadingController: LoadingController
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Agregar encabezados personalizados a la solicitud
    const modifiedRequest = request.clone({
     /* headers: request.headers.set( 
        'Authorization', `Basic Z3N1aXRlLWRyaXZlOkpHT2FwaURyaXZlLmdhdWRpMTIzNDU2MA==`, 
       // 'Authorization',`Basic ZmlyZWJhc2VAanVhbmdvLmNvbS51eTpGMXIzYkBzMw==` 
      )*/
    }); 
    // Continuar con la solicitud modificada
    return next.handle(modifiedRequest);
  }

}