import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {Empresa} from '../../core/models';

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {


  private empresaCollection: AngularFirestoreCollection<Empresa>;

  private empresa: Observable<Empresa[]>;

  constructor(private db: AngularFirestore) {
   

    
  }

  getEmpresas(c) {
    this.empresaCollection = this.db.collection<Empresa>(c);
    this.empresa = this.empresaCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const doc_id = a.payload.doc.id;
          return { doc_id, ...data };
        });
      })
    );
    //console.log(this.empresa)
    return this.empresa;
  }

  getEmpresa(id) {
    return this.empresaCollection.doc<Empresa>(id).valueChanges();
  }

  updateEmpresa(Empresa: Empresa, id: string) {
    return this.empresaCollection.doc(id).update(Empresa);
  }

  addEmpresa(Empresa: Empresa) {
    //console.log(Empresa);
    return this.empresaCollection.add(Empresa);
  }

  removeEmpresa(id) {
    return this.empresaCollection.doc(id).delete();
  }

  getSubEmpresasCollection(id){
    //return this.EmpresaCollection.doc<EmpresaOperativa>(id).collection<EmpresaOperativa>('subEmpresas').valueChanges()

    return this.empresaCollection.doc<Empresa>(id).collection<Empresa>('subEmpresas', ref => ref.orderBy("timestamp")).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const doc_id = a.payload.doc.id;
          return { doc_id, ...data };
        })
      })
    );
   }

   addEmpresaEmpresa(id, Empresa: Empresa) {
    //console.log(id);
    return this.empresaCollection.doc(id).collection('subEmpresas').add(Empresa)

  }

}

