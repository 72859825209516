import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable()
export class AlertFactoryService {

    constructor(protected alertCtrl: AlertController) {

    }

    async confirm(
        handlers?: { ok?: any, cancel?: any },
        title: string = 'Confirmar',
        message: string = '¿Está seguro de que quiere realizar esta acción?'
    ): Promise<any> {
        if (!handlers) {
            handlers = {
                ok: () => {},
                cancel: () => {}
            };
        }

        return this.alertCtrl.create({
            header: title,
            message,
            buttons: [
                {
                    text: 'Cancelar',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: handlers.cancel
                },
                {
                    text: 'Confirmar',
                    cssClass: 'secondary',
                    handler: handlers.ok
                }
            ]
        });
    }

    async error(
        handlers?: { ok?: any },
        title: string = 'Error',
        message: string = 'Ocurrió un error inesperado.'
    ): Promise<any> {
        if (!handlers) {
            handlers = {
                ok: () => {
                }
            };
        }

        return this.alertCtrl.create({
            header: title,
            message,
            buttons: [
                {
                    text: 'Ok',
                    cssClass: 'secondary',
                    handler: handlers.ok
                }
            ]
        });
    }
}
