import { Injectable } from '@angular/core';
import { isUrl } from '../utilities'; 


@Injectable()
export class FileService {

    readonly IMAGE_EXTENSIONS: ReadonlyArray<string> = ['JPG', 'JPEG', 'GIF', 'PNG'];
    readonly PDF_EXTENSION: string = 'pdf';

    constructor() {
    }

    getFileExtension(fileName: string): string {
        if (!fileName) {
            throw new Error('No specified file name.');
        }
        const splitString = fileName.split('.');
        // Last index
        return splitString[splitString.length - 1];
    }

    /**
     * Reads a HTML input file and converts it to a buffered array.
     * @param file HTML input file (not an array)
     */
    async getBufferedFile(file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
        });
    }

    bufferToBlob(contentType: string, buffer: any): Blob {
        if (!buffer) {
            throw new Error('Buffer is empty');
        }
        return new Blob([new Uint8Array(buffer)], {type: contentType});
    }

    isExtensionImageOrPDF(extension: string): boolean {
        return this.isExtensionImage(extension) || extension === this.PDF_EXTENSION;
    }

    isExtensionImage(extension: string): boolean {
        return this.IMAGE_EXTENSIONS.includes(extension.toUpperCase());
    }

    isExtensionPDF(extension: string): boolean {
        return extension === this.PDF_EXTENSION;
    }

    isUrlPDF(url): boolean {
        if (!url || typeof url !== 'string') {
            return false;
        }
        return isUrl(url) && url.toLowerCase().endsWith(this.PDF_EXTENSION);
    }

    isUrlImage(url): boolean {
        if (!url || typeof url !== 'string') {
            return false;
        }
        const _url = url.toUpperCase();
        return isUrl(url) && !!this.IMAGE_EXTENSIONS.find(ext => _url.endsWith(ext));
    }

    fileUrlToArrayBuffer(fileUrl: any): any {
        return new Promise((resolve, reject) => {
            const request = new XMLHttpRequest();
            request.open('GET', fileUrl, true);
            request.responseType = 'blob';
            request.onerror = reject;
            request.onload = () => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(request.response);
                reader.onload =  e => {
                    resolve(reader.result);
                };
            };
            request.send();
        });
    }

    isFileOrBlob(obj: any): boolean {
        return obj && obj instanceof File || obj instanceof Blob;
    }

    /***
     *  Convierte 1 input de camara a blob, si es un file devuelve el file.
     * */
    blobifyFormValue(value: any): Promise<File | Blob | ArrayBuffer> {
        return this.isFileOrBlob(value) ? value
          : value.webPath ? this.fileUrlToArrayBuffer(value.webPath)
            : null;
    }

    downloadFromUrl(url: string): void {
      const element = document.createElement('a');
      element.setAttribute('href', url);
      element.setAttribute('download', 'Download');
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }
}
