import { Component, OnInit, Input } from '@angular/core';
import { tipoeventoctn, Contenedor } from '../../../core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiserviceService } from '../../services/apiservice.service';
import { EmpresasService } from '../../services/empresas.service';
import { ModalController, ToastController, AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { AngularFirestore } from '@angular/fire/firestore';
import { ApiService } from '../../services/api.service';
import { ModalSelectEventoPage } from './modalselectevento/modalselectevento.page';
import { Logger } from 'src/app/core/log';

@Component({
  selector: 'app-modaleventoctn',
  templateUrl: './modaleventoctn.page.html',
  styleUrls: ['./modaleventoctn.page.scss'],
})
export class ModaleventoctnPage implements OnInit {
  @Input() contenedor: Contenedor

  precintoGuardado = "";
  bultosGuardado = "";
  registroForm: FormGroup;
  empresas: Array<{ nombre: string, nombresistema: string }>;
  tokenlocals: Array<{ id: string, colleccion: string }>;
  tiposeventos: Array<tipoeventoctn>;
  empresaslist: any;
  notification: boolean = false;
  tokenls = "";
  public fumigado = false;
  public consolidado = false;
  public fumigadoI = false;
  public consolidadoI = false;
  public vpinchazootro = false;
  eventosctninfo: any[] = [];
  evento="";

  constructor(
    private toastrController: ToastController,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    public apiservice: ApiserviceService,
    public alertController: AlertController,
    public empservice: EmpresasService,
    protected api: ApiService,
    private db: AngularFirestore) {
    this.precintoGuardado = "";
    this.bultosGuardado = "";

    this.registroForm = this.formBuilder.group({
      // empdefaultpesadas: ['', Validators.compose([Validators.minLength(5)])],
      valor: ['', Validators.compose([Validators.required])],
      evento: ['', Validators.compose([Validators.required])]
    });
  }

  ngOnInit() {
    this.cargaEventosGaudi()
  }


  async guardar() {
    const evento = this.evento;
    const valor = this.registroForm.value.valor;
    const alert = await this.alertController.create({
      header: 'Guardar?',
      message: evento + " " + valor + '?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.postEventos(valor, evento);
          }
        }
      ]
    });
    await alert.present();
  }

  async postEventos(lote, tipo) {
    let fecha = new Date().toISOString();
    //let fecha = date.getFullYear().toString()+"-"+(date.getMonth()+1).toString()+"-"+date.getDate().toString()+" "+date.getHours().toString()+":"+date.getMinutes().toString()+":"+date.getSeconds().toString();
    let logscan = { nombreevento: tipo, fecha: fecha, contenedor: this.contenedor.numeroctn, lote: lote + "", meta: "" };


    /*  this.apiservice.postEvento(logscan).subscribe(data => {
        //console.log(data);
        this.toastrController.create({
          message: tipo + ' Registrado',
          duration: 1500
        }).then(res => {
          res.present()
        })
  
      });*/

    this.api.guardarEventoContenedor(logscan).subscribe(data => {

      this.toastrController.create({
        message: 'Registrado',
        duration: 1500
      }).then(res => {
        res.present()
      })
      //this.cargaEventost();
    }, err => {

      this.toastrController.create({
        message: 'Error en Registro',
        duration: 1500
      }).then(res => {
        res.present()
      })
    });

  }

  closeModal() {
    this.modalController.dismiss({
    });
  }


  async selectevento() {

    let fecha = new Date().toISOString();
    const modal = await this.modalController.create({
      component: ModalSelectEventoPage,
      componentProps: {
      }
    }).then(async modalAfterCreate => {
      modalAfterCreate.present();

      const { data } = await modalAfterCreate.onDidDismiss();
      if(data.evento){
        this.registroForm.patchValue({
          ['evento']: data.tipo
        });
        this.evento=data.evento;
      }
      //this.cargarContenedor();
    });
  }
  cargaEventosGaudi() {
    // console.log("items consolidados");
    this.apiservice.getEventosCTN(this.contenedor.numeroctn).subscribe(data => {
      for (let i in data) {
           this.eventosctninfo.push(data[i])
      }
    }, err => {

      //console.log(err);
    });
  }
}
