//api.service.ts
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpInterceptor, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {RequestOptions, Request, RequestMethod} from '@angular/http';
import { HttpClientModule } from "@angular/common/http";
import { Booking } from '../../core/models';
import { Pesadas } from '../../core/models';
import { PesadasGaudi } from '../../core/models';
import { Evento } from '../../core/models';
import { EventoPost } from '../../core/models';
import { Contenedor } from '../../core/models';
import { Empresa } from '../../core/models';
import { EventoPesada } from '../../core/models';
import { AngularFireDatabaseModule, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { DIAS_CONTENEDOR, DIAS_BOOKING, CURRENT_PESADAS, DEFAULT_EMPRESA, DIAS_EVENTOSPESDA } from '../constants/local-storage';
import { DateUtils } from '../../shared/utils/date-utils';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { DatePipe } from '@angular/common';

const headerDict = { 
  authorization: "Basic Z3N1aXRlLWRyaXZlOkpHT2FwaURyaXZlLmdhdWRpMTIzNDU2MA==",
  'Content-Type': 'application/json',
  'Accept': 'application/json',
  'Access-Control-Allow-Headers': 'Content-Type',
};

@Injectable({
  providedIn: 'root'
})
export class ApiserviceService implements OnInit {
  itemsRef: AngularFireList<any>;

  Empresas: Array<{ nombre: string, nombresistema: string }>;
  items: Observable<any[]>;
  private api = "https://api-v2.juango.com.uy/api/";
  private URLctn = "anunciados/list/";
  private URLgetev = "anunciados/consolidadolist/contenedor/list/";
  private URLgetevctn = "anunciados/consolidadolist/contenedor/";
  diascontenedores = 60;
  diasbooking = 20;
  diaspesadas = 3;
  diaseventospesdas = 30;

  constructor(public db: AngularFireDatabaseModule, private http: HttpClient,
    private datePipe: DatePipe,
    // public b64:base64
  ) {


  }


  ngOnInit() {
  }

  addItem(nodo: string, objeto: {}) {
    //console.log(objeto);
    //  return this.db.list(nodo).push(objeto);
  }
  //getItems(nodo: string):AngularFireList<Empresa> {
  // return this.db.list(nodo);
  //}


  getPesadasGaudi(): Observable<PesadasGaudi[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let dp = window.localStorage.getItem(CURRENT_PESADAS);
    if (dp != null) { this.diaspesadas = parseInt(dp) };
    let fecha2 = DateUtils.getXDaysBefore(new Date(), this.diaspesadas);
    // let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    let fechahoy = this.datePipe.transform(new Date(), 'yyyy-MM-dd')

    let URLgetentradas = this.api+"pesadas/list/?&fechainicio=" + fecha + "&fechafin=" + fechahoy;
    //console.log(URLgetentradas);
    return this.http.get<PesadasGaudi[]>(URLgetentradas).pipe(
      tap(_ => this.log('response received')),
      catchError(this.handleError<PesadasGaudi[]>('getList', []))
    );
  }

  getPesadas(): Observable<Pesadas[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    //fecha2.setDate(fecha2.getDate() - dias);
    let fecha2 = DateUtils.getXDaysBefore(new Date(), 3);
    // let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    let fechahoy = this.datePipe.transform(new Date(), 'yyyy-MM-dd')   
    let URLgetentradas = this.api+"balanza/pesadas/?fechainicio=" + fecha + "&fechafin=" + fechahoy;
     console.log(URLgetentradas);
    return this.http.get<Pesadas[]>(URLgetentradas);
  }

  getPesadasxtipo(t): Observable<Pesadas[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    //fecha2.setDate(fecha2.getDate() - dias);
    let fecha2 = DateUtils.getXDaysBefore(new Date(), 3);
    // let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    let fechahoy = this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    let URLgetentradas = this.api+"pesadas/?fechainicio=" + fecha + "&fechafin=" + fechahoy;
     return this.http.get<Pesadas[]>(URLgetentradas);
  }

  getContenedores(): Observable<Contenedor[]> {
    let dc = window.localStorage.getItem(DIAS_CONTENEDOR);
    if (dc != null) { this.diascontenedores = parseInt(dc) };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    }; 

    // fecha2.setDate(fecha2.getDate() - dias);
    //let fechahoy=this.datePipe.transform(new Date(), 'yyyy-MM-dd')
    let fecha2 = DateUtils.getXDaysBefore(new Date(), this.diascontenedores);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);
    const url = this.api+"anunciados/list/?&format=json&fechainicio=" + fecha + "";

    return this.http.get<Contenedor[]>(url)
      .pipe(
        tap(_ => this.log('response received')),
        catchError(this.handleError<Contenedor[]>('getList', []))
      );
  }

  getEventosCTN(ctn: string): Observable<any[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    //console.log("https://api.juango.com.uy/v2/index.php/api/anunciados/consolidadolist/contenedor/"+ctn);
    return this.http.get<any[]>(this.api+"anunciados/consolidadolist/contenedor/" + ctn);
  }

  getContenedor(ctn: string): Observable<Contenedor> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    //console.log("https://api.juango.com.uy/v2/index.php/api/anunciados/list/numero/"+ctn);
    return this.http.get<Contenedor>(this.api+"anunciados/list/numero/" + ctn);
  }

  getTEventosCTN(): Observable<Evento[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    return this.http.get<Evento[]>(this.api+"anunciados/consolidadolist/fechainicio/2019-06-08");
  }

  getBooking1(): Observable<Booking[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let dbg = window.localStorage.getItem(DIAS_BOOKING);
    if (dbg != null) { this.diasbooking = parseInt(dbg) };
    //fecha2.setDate(fecha2.getDate() - dias);
    //let fechahoy=this.datePipe.transform(new Date(), 'yyyy-MM-dd') 
    let fecha2 = DateUtils.getXDaysBefore(new Date(), this.diasbooking);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);
    return this.http.get<Booking[]>(this.api+"bookings/list/?&format=json&fechainicio=" + fecha + "" ).pipe(
      tap(_ => this.log('response received')),
      catchError(this.handleError('getDetails', []))
    );
    // return this.http.get<Booking[]>("http://api.juango.com.uy/v3/index.php/api/bookings/list/?&format=json&fechainicio="+fecha+"", httpOptions);

  }
  getBooking(): Observable<Booking[]> {
    let dbg = window.localStorage.getItem(DIAS_BOOKING);
    if (dbg != null) { this.diasbooking = parseInt(dbg) };
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    //fecha2.setDate(fecha2.getDate() - dias);
    let fecha2 = DateUtils.getXDaysBefore(new Date(), this.diasbooking);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    //let fechahoy=this.datePipe.transform(new Date(), 'yyyy-MM-dd') 

    let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);


    const url = this.api+"bookings/list/?&format=json&fechainicio=" + fecha + "";
    return this.http.get<Booking[]>(url)
      .pipe(
        tap(_ => this.log('response received')),
        catchError(this.handleError<Booking[]>('getList', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    //console.log(message);
  }

  postEvento(e: EventoPost): Observable<any> {
    let json = JSON.stringify(e);
    let params = "json=" + json;
    //console.log(e);
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.api+"anunciados/consolidadoevento/", e);
  }

  postEventoPesada(e: EventoPost): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let json = JSON.stringify(e);
    let params = "json=" + json;
    //console.log(e);
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.post(this.api+"pesadas/evento/", e).pipe(
      tap(_ => this.log('response received')),
      catchError(this.handleError<EventoPost[]>('getList', []))
    );
  }

  getEventosPesada(pesada: string): Observable<EventoPesada[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let dbg = window.localStorage.getItem(DIAS_EVENTOSPESDA);
    if (dbg != null) { this.diaseventospesdas = parseInt(dbg) };
    var fecha2 = new Date();
    var dias = this.diaseventospesdas; // Número de días a agregar
    //fecha2.setDate(fecha2.getDate() - dias);
    //let fecha=this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    //let fechahoy=this.datePipe.transform(new Date(), 'yyyy-MM-dd') 
    let fecha = DateUtils.getXDaysBefore(new Date(), this.diaseventospesdas);
    let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);
    //console.log("https://api.juango.com.uy/v2/index.php/api/pesadas/eventolist/numero/"+pesada)
    return this.http.get<EventoPesada[]>(this.api+"pesadas/eventolist/fechainicio/" + fecha + "/" + "numeropesada/" + pesada);
  }

  getEventosPesada2(pesada: string): Observable<EventoPesada[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let fecha2 = DateUtils.getXDaysBefore(new Date(), 3);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    //console.log("https://api.juango.com.uy/v2/index.php/api/pesadas/eventolist/numero/"+pesada)
    return this.http.get<EventoPesada[]>(this.api+"pesadas/eventolist/numero/" + pesada);
  }


  getEventosPesadas(): Observable<EventoPesada[]> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    let dbg = window.localStorage.getItem(DIAS_EVENTOSPESDA);
    if (dbg != null) { this.diaseventospesdas = parseInt(dbg) };
    //fecha2.setDate(fecha2.getDate() - dias);
    let fecha2 = DateUtils.getXDaysBefore(new Date(), this.diaseventospesdas);
    let fecha = this.datePipe.transform(fecha2, 'yyyy-MM-dd');
    //let fechahoy=this.datePipe.transform(new Date(), 'yyyy-MM-dd') 
    let fechahoy = DateUtils.getXDaysBefore(new Date(), 1);
    //console.log("https://api.juango.com.uy/v2/index.php/api/pesadas/eventolist/fechainicio/"+fecha+"");
    return this.http.get<EventoPesada[]>(this.api+"pesadas/eventolist/fechainicio/" + fecha + "")
  }

  eliminLotePesada(e): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    //let json = JSON.stringify(e);
    // let params = "json="+json;
    //console.log(e);
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.delete(this.api+"pesadas/evento/" + e).pipe(
      tap(_ => this.log('response received')),
      catchError(this.handleError<EventoPost[]>('getList', []))
    );
  }


  eliminLoteConsolidado(e): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders(headerDict),
    };
    //let json = JSON.stringify(e);
    // let params = "json="+json;
    //console.log(e);
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    return this.http.delete(this.api+"anunciados/consolidadoevento/" + e).pipe(
      tap(_ => this.log('response received')),
      catchError(this.handleError<EventoPost[]>('getList', []))
    );
  }
}

