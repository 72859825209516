import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModaleventoctnPage } from './modaleventosctn/modaleventoctn.page';
import { ModaltrasbordoPage } from './modaltrasbordo/modaltrasbordo.page';
import { ModalregistrorolosPage } from './modalregistrorolos/modalregistrorolos.page';
import { ModalconfigPage } from './modalconfig/modalconfig.page';
import { ModalregistcomentPage } from './modalregistcoment/modalregistcoment.page';
import { ModalinfoctnPage } from './modalinfoctn/modalinfoctn.page';
import { ModalSelectEventoPage } from './modaleventosctn/modalselectevento/modalselectevento.page'
// import { ModaleventoctnPage } from './Modaleventosctn/modaleventoctn.page';
import { FileSizeFormatPipe } from '../utils/file-size-format.pipe';
import { from } from 'rxjs';
import { ViajesContenedorFormComponent } from './viajes-contenedor-form/viajes-contenedor-form.component';



@NgModule({
    declarations: [
        ModaltrasbordoPage,
        ModalregistrorolosPage,
        ModalconfigPage,
        ModalregistcomentPage,
        ModalinfoctnPage,
        ModaleventoctnPage,
        ModalSelectEventoPage,
        FileSizeFormatPipe,
        ViajesContenedorFormComponent
    ],
    entryComponents: [
        ModaltrasbordoPage,
        ModalregistrorolosPage,
        ModalconfigPage,
        ModalregistcomentPage,
        ModalSelectEventoPage,
        ModalinfoctnPage,
        ModaleventoctnPage,
        ViajesContenedorFormComponent
    ],
    imports: [
        CommonModule,
        IonicModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        ModaltrasbordoPage,
        ModalregistrorolosPage,
        ModalSelectEventoPage,
        ModalconfigPage,
        ModalregistcomentPage,
        ModalinfoctnPage,
        ModaleventoctnPage,
        ViajesContenedorFormComponent
        
    ],
    providers: [
    ],
    bootstrap: []
})
export class ComponentsModule { }
