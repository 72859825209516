import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonInput, ModalController } from '@ionic/angular';
import { Constants } from '../../../app.constants';
import { ISO6346ContainerValidator, iso6346Validator } from '../../utils/container.validator';
import { polluteForm } from '../../utils/utils';
import { ToastFactoryService } from '../../utils/toast-factory.service';
import { AppLoadingController } from '../../utils/app-loading.controller';
import { preAnuncio } from 'src/app/core/models';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-viajes-contenedor-form',
  templateUrl: './viajes-contenedor-form.component.html',
  styleUrls: ['./viajes-contenedor-form.component.scss']
})
export class ViajesContenedorFormComponent implements OnInit, OnDestroy {

  @ViewChild('letras', {static: true}) letras: IonInput;
  @ViewChild('digitos', {static: true}) digitos: IonInput;
  @ViewChild('verificador', {static: true}) verificador: IonInput;

  form: FormGroup;
  // Modal
  constructor(
    protected loading: AppLoadingController,
    protected toast: ToastFactoryService,
    protected modalCtrl: ModalController,
    protected fb: FormBuilder,
    protected datePipe:DatePipe
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      letras: [null, [Validators.required, Validators.pattern(Constants.CONTENEDOR_LETRAS)]],
      digitos: [null, [Validators.required]],
      verificador: [null, [Validators.required]]
    }, {
      validators: [ISO6346ContainerValidator.validateFormGroup({
        registrationDigit: 'letras',
        ownerAndGroupCode: 'digitos',
        digit: 'verificador'
      })]
    });
    this.form.get('letras').valueChanges.subscribe(c => {
      console.log(c);
    })
  }

  ngOnDestroy(): void {
  }

  onLetrasKeyInput(ev: any): void {
    const value = this.form.get('letras').value;
    if (value?.length === 4) {
      this.focus(this.digitos);
    }
  }

  onDigitoKeyInput(ev: any): void {
    const value = this.form.get('digitos').value;
    if (value) {
      if (String(value).length === 6) {
        this.focus(this.verificador);
        this.calcularVerificador();
      }
    }
  }

  /**
   * Calcula el verificador en base a los 2 primeros inputs.
   */
  calcularVerificador(): void {
    const formValue = this.form.value;
    const letras = formValue.letras;
    const digitos = formValue.digitos;
    if (letras && digitos) {
      const matricula = `${letras}${digitos}`;
      const checkDigit = iso6346Validator.createCheckDigit(matricula);
      if (checkDigit && checkDigit >= 0) {
        this.form.patchValue({
          verificador: checkDigit
        });
      }
    }
  }

  focus(element: IonInput): void {
    element.getInputElement().then(el => {
      el.focus();
    });
  }

  async submit(): Promise<void> {
    if (this.form.valid) {
      const load = await this.loading.createDefault();
      try {
        await load.present();
        const numeroContenedor = this.getMatricula();
        
        let fecha = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
        let panu: preAnuncio;
        panu={
              numeroctn:numeroContenedor, 
              fecha:fecha,
              gaudi_registrado: false,
              matricula: "",
              empresa: "",
              nombreproducto: "",
              booking:"",
              bookingnumero:"",
              tipo:"ctn-piso"
            }
            
        console.log(panu);
        await this.guardar(panu);
      } catch (e) {
        await this.displayError(e);
      } finally {
        await load.dismiss();
      }
    } else {
      polluteForm(this.form);
    }
  }

  getMatricula(): string {
    const formValue = this.form.value;
    return `${formValue.letras}${formValue.digitos}${formValue.verificador}`;
  }

  async displaySuccess(): Promise<void> {
    const toast = await this.toast.success('Cambios guardados');
    await toast.present();
  }

  async displayError(err: any): Promise<void> {
    const toast = await this.toast.error('Ocurrió un error al guardar');
    await toast.present();
  }

  userCompletedFilling(): boolean {
    const letrasCtrl = this.form.get('letras');
    const digitsCtrl = this.form.get('digitos');
    const verificadorCtrl = this.form.get('verificador');
    return (letrasCtrl.dirty && letrasCtrl.touched) &&
      (digitsCtrl.dirty && digitsCtrl.touched) &&
      (verificadorCtrl.dirty && verificadorCtrl.touched);
  }

  async close(): Promise<void> {
    await this.modalCtrl.dismiss();
  }

  async guardar(a): Promise<void> {
    await this.modalCtrl.dismiss({
      'anuncio': a
    });
  }
  
}
