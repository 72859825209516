import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as ContainerValidator from 'container-validator';

export const iso6346Validator = new ContainerValidator();

export interface ISO6346FormGroup {
  ownerAndGroupCode: string;
  registrationDigit: string;
  digit: string;
}

export abstract class ISO6346ContainerValidator {

  static validateFormGroup(controls: ISO6346FormGroup): ValidatorFn  {
    return (formGroup: AbstractControl): { [key: string]: boolean } | null => {
      const ownerAndGroupCode = formGroup.get(controls.ownerAndGroupCode);
      const registrationDigit = formGroup.get(controls.registrationDigit);
      const digit = formGroup.get(controls.digit);
      const first = registrationDigit.value;
      const second = ownerAndGroupCode.value;
      const third = digit.value;
      if (first && second && third) {
        const numero_contenedor = `${first}${second}${third}`;
        if (!iso6346Validator.isValid(numero_contenedor)) {
          return {
            invalidContenedor: true
          };
        }
      }
    };
  }

}
