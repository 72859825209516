import * as moment from 'moment';
import { Platform } from '@ionic/angular';
import { FormArray, FormGroup } from '@angular/forms';

export function datePickerToMoment(date: any): moment.Moment {
    let result = moment();
    if (date.year && date.year.value) {
        result = result.year(date.year.value);
    }
    if (date.month && date.month.value) {
        result = result.month(date.month.value - 1);
    }
    if (date.day && date.day.value) {
        result = result.date(date.day.value);
    }
    if (date.hour && date.hour.value) {
        result = result.hour(date.hour.value);
    }
    if (date.minute && date.minute.value) {
        result = result.minutes(date.minute.value);
    }

    return result;
}

export function momentToDatePicker(momentDate: any): any {
    return {
        year: {value: momentDate.year()},
        month: {value: momentDate.month() + 1},
        day: {value: momentDate.date()},
        hour: {value: momentDate.hour()},
        minute: {value: momentDate.minutes()}
    };
}

export function isDatePickerObject(date: any): boolean {
    if (date.year && date.year.value) {
        return true;
    }
    if (date.month && date.month.value) {
        return true;
    }
    if (date.day && date.day.value) {
        return true;
    }
    if (date.hour && date.hour.value) {
        return true;
    }
    if (date.minute && date.minute.value) {
        return true;
    }

    return false;
}

export function clone(object: any): any {
    return JSON.parse(JSON.stringify(object));
}

export abstract class ArrayUtils {

    static isNotEmpty(arr: Array<any>): boolean {
        return arr && arr.length > 0;
    }

    static isEmpty(arr: Array<any>): boolean {
        return !ArrayUtils.isNotEmpty(arr);
    }

}

/**
 * Esto se va cuando agreguen el plugin de g+
 */
export function isMobile(platform: Platform): boolean {
    return platform.is('cordova');
}

export function getRandomId(): string {
    return Math.random().toString(36).substring(2);
}

export function convertFileSrc(uri: string): string {
    // @ts-ignore
    return window.Ionic.WebView.convertFileSrc(uri);
}

export function normalizeURL(uri: string): string {
    return convertFileSrc(uri);
}

export function fileToArrayBuffer(fileUrl: string): any {
    return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', fileUrl, true);
        request.responseType = 'blob';
        request.onload = () => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(request.response);
            reader.onload =  e => {
                resolve( this.result);
            };
        };
        request.send();
    });
}

export const polluteForm = (group: FormGroup | FormArray) => {
    for (const control in group.controls) {
        if (group.controls.hasOwnProperty(control)) {
            const formControl = group.controls[control];
            if (formControl instanceof FormGroup || formControl instanceof FormArray) {
                // If this is another form group inside, then recursively pollute form
                polluteForm(formControl);
            } else {
                formControl.markAsDirty();
                formControl.markAsTouched();
                formControl.updateValueAndValidity();
            }
        }
    }
};
