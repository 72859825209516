import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest, from, NEVER, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, first, map, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';

import { auth, User } from 'firebase/app';
import { Logger } from '../../core/log';
import { ErrorMessages } from '../error';
import UserCredential = firebase.auth.UserCredential;
import AuthProvider = firebase.auth.AuthProvider;
import { Platform } from '@ionic/angular';
import { isMobile } from '../../shared/utils/utils';
import { ApiError } from './rest.service';
import { Collection } from './collections';
import { Usuario, UserProfile } from '../models';
import { FirestoreService } from './firestore.service';

import firebase from 'firebase/app';


export declare type Profile = UserProfile

@Injectable()
export class AuthService {

  user: Observable<Profile>;
  protected onLogout$: Subject<any>;
  protected onLogin$: Subject<any>;

  constructor(
    protected errorMessages: ErrorMessages,
    protected service: FirestoreService,
    protected afAuth: AngularFireAuth,
    protected logger: Logger,
    protected platform: Platform) {
    this.onLogout$ = new Subject();
    this.onLogin$ = new Subject<any>();
    this.listenAuth();
  }

  listenAuth(): void {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.onLogin$.next();
          return combineLatest([of(user), this.service.query(Collection.USERS).find(user.uid)]);
        } else {
          this.onLogout$.next();
          return NEVER;
        }
      }),
      map((result: Array<any>) => {
          if (result) {
            const user = result[0];
            const userProfile = result[1];
            return this.mapUser(user, userProfile);
          }
        }
      ),
      tap(async user => {
        if (user?.uid) {
          try {
            //this.analytics.setUserId(user.uid);
          } catch (e) {
            this.logger.error('Analytics Error');
            this.logger.error(e);
          }
        }
      }),
      publishReplay(1),
      refCount()
    );
  }

  protected mapUser(user: User, userProfile: Profile): Profile {
    const profile = userProfile ? userProfile : {};
    return {
      id: user.uid,
      ... user,
      ... profile
    }
  }

  async isUserLogged(): Promise<boolean> {
    return this.afAuth.user
      .pipe(take(1), map(user => !!user))
      .toPromise();
  }

  onLogout(): Observable<any> {
    return this.onLogout$.asObservable();
  }

  onLogin(): Observable<any> {
    return this.onLogin$.asObservable();
  }

  getUser(): Observable<Profile> {
    // hacer copia para evitar problemas con cambios globales.
    return this.user
      .pipe(map(user => {
        return user;
      }));
  }

  getUserAsync(): Promise<Profile> {
    return this.getUser()
      .pipe(
        first()
      )
      .toPromise();
  }

  registerUser(email: string, password: string): Observable<UserCredential> {
    return from(this.afAuth.createUserWithEmailAndPassword(email, password))
      .pipe(
        catchError(err => throwError(this.mapError(err)))
      );
  }

  loginUser(email: string, password: string): Observable<any> {
    return from(this.afAuth.signInWithEmailAndPassword(email, password))
      .pipe(catchError(err => throwError(this.mapError(err))));
  }

  resetPassword(email: string): Observable<any> {
    return from(this.afAuth.sendPasswordResetEmail(email))
      .pipe(catchError(err => throwError(this.mapError(err))));
  }

  async logout(): Promise<any> {
    return this.afAuth.signOut();
  }

  protected mapError(error: any): void {
    // Get error from errorMessages, if not found get the firebase error or a generic message if not defined.
    const message = error.code ? this.errorMessages.getMessage(error.code) : error.message || this.errorMessages.getGenericMessage();
    this.logger.error(error);
    throw new ApiError(error, message);
  }

}
