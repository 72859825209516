export abstract class PAGES {
    static readonly LOGIN: Array<string> = ['login'];
    static readonly HOME: Array<string> = ['home'];
    static readonly VER_DETALLE_CTN: Array<any> = ['verctn'];
    static readonly EMPRESAS: Array<any> = ['empresa'];
    static readonly BOOKING: Array<any> = ['booking'];
    static readonly PESADAS: Array<any> = ['pesadaslist'];
    static readonly VER_CTNEMPRESA: Array<string> = ['verlistctn'];
    static readonly SUBIR_ARCHIVO: Array<string> = ['subir-archivo'];
    static readonly VER_DETALLE_PESADA: Array<string> = ['pesadadetalle'];
    static readonly LISTADO_REGISTROS: Array<string> = ['listado-registros'];disconnected
    static readonly LISTADO_EVENTOSPESADAS: Array<string> = ['eventospesadaslist'];
    static readonly DISCONNECTED: Array<string> = ['disconnected'];
}

export const CONFIG = {
    FORMATS: {
        DATE: 'DD/MM/YYYY',
        DATE_TIME: 'DD/MM/YYYY HH:mm',
        TIME: 'HH:mm',
        DATE_FB: 'YYYY-MM-DD'
    }
};

export const Constants = {
    MATRICULA_REGEXP: '^[a-zA-Z]{3}[\\d]{4}',
    CELULAR_REGEXP: '^09[0-9]\s?[0-9]{6}',
    CONTENEDOR_LETRAS: '^[a-zA-Z]{4}$',
    CONTENEDOR_DIGITOS: '^[0-9]{6}$',
    CONTENEDOR_VERIFICADOR: '^[0-9]{1}$'
};

export const GOOGLE_WEB_CLIENT_ID = '295061332117-hic0k3khqqfk4kvmrdkruhr3hkh59i5v.apps.googleusercontent.com';
// Constantes para los tipos de los request que se guardan offline
export const PESADA_GUARDAR = 'pesadaGuardar';
export const PESADA_BORRAR = 'pesadaBorrar';
export const LOTE_GUARDAR = 'loteGuardar';
export const LOTE_BORRAR = 'loteBorrar';
