
export const environment = {
  production: false,

  FIREBASE_CONFIG: {
    apiKey: "AIzaSyCLie90C87TTep0lfR7fef99cI04Qw5t20",
    authDomain: "consolidadoapp.firebaseapp.com",
    databaseURL: "https://consolidadoapp.firebaseio.com",
    projectId: "consolidadoapp",
    storageBucket: "consolidadoapp.appspot.com",
    messagingSenderId: "850813438886",
    appId: "1:850813438886:web:e45529986796d79ad41d67"
  }
};
