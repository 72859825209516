import { Injectable } from '@angular/core';

const noop = (): any => undefined;

/**
 * A common logger that is used by the app.
 */
@Injectable()
export class Logger {

  constructor() {

  }

  private invokeConsoleMethod(type: string, ...args: Array<any>): void {
    // Don't log in production
    const logFn: Function = (console)[type] || console.log || noop;
    logFn.apply(console, ...args);
  }

  info(...args: Array<any>): void {
    this.invokeConsoleMethod('info', args);
  }

  warn(...args: Array<any>): void {
    this.invokeConsoleMethod('warn', args);
  }

  error(...args: Array<any>): void {
    this.invokeConsoleMethod('error', args);
  }

  debug(...args: Array<any>): void {
    this.invokeConsoleMethod('debug', args);
  }
}
