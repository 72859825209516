import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PAGES } from './app.constants';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ConnectionStatus, NetworkService } from './shared/services/network.service';
import { OfflineManagerService } from './shared/services/offline-manager.service';
import { IonMenu, NavController } from '@ionic/angular';
import { DIAS_CONTENEDOR, ESCANEO_CONTINUO,CONTROL_LOTE, DIAS_BOOKING,INICIO_MENU, CURRENT_PESADAS, DEFAULT_EMPRESA,PAGE_INICIO, DIAS_EVENTOSPESDA, EMPRESAS_MENU, BOOKING_MENU, PESADAS_MENU, FOTOS_MENU, EP_MENU, CTN_MENU } from './shared/constants/local-storage';

import { UsersService } from './shared/services/users.service';
//import { FcmService } from './shared/services/fcm.service';
import { ToastService } from './shared/services/toast.service';
import { ToastController, AlertController, ModalController } from '@ionic/angular';
import { AuthService } from './core/api/auth.service';
import { ModalconfigPage } from './shared/components/modalconfig/modalconfig.page';
import { UserProfile } from './core/models';
import { Observable} from 'rxjs';
import { AuthGuard } from './shared/guards/auth.guard';

export declare type Profile = UserProfile


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  navigate: any;
  private authListener;
  public empresasmenu;
  public bookingmenu;
  public pesadasmenu;
  public fotosmenu;
  public epmenu;
  public iniciomenu;
  public ctnmenu
  user: Observable<Profile>;

  constructor(
    protected authService: AuthService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private afAuth: AngularFireAuth,
    public modalCtrl: ModalController,
    public alertController: AlertController,
    private statusBar: StatusBar,
    private router: Router,
    //private fcm: FcmService,
    private toastr: ToastService,
    public toastController: ToastController,
    private auth: AuthService,
    protected navCtrl: NavController,
    private uservice: UsersService,
    private offlineManager: OfflineManagerService,
    private networkService: NetworkService
  ) {
      this.preconfig();
      this.initializeApp();
  }

  async logout(): Promise<void> {
    await this.navCtrl.navigateRoot(PAGES.LOGIN as Array<string>, {replaceUrl: true});
    setTimeout(async () => {
      await this.authService.logout();
    }, 500)
  }

  sideMenu() {
    this.empresasmenu = (window.localStorage.getItem(EMPRESAS_MENU) == 'true');
    this.bookingmenu = (window.localStorage.getItem(BOOKING_MENU) == 'true');
    this.pesadasmenu = (window.localStorage.getItem(PESADAS_MENU) == 'true');
    this.fotosmenu = (window.localStorage.getItem(FOTOS_MENU) == 'true');
    this.epmenu = (window.localStorage.getItem(EP_MENU) == 'true');
    this.iniciomenu = (window.localStorage.getItem(INICIO_MENU) == 'true');
    this.ctnmenu = (window.localStorage.getItem(CTN_MENU) == 'true');
    this.navigate = [];
    this.navigate =
      [
        {
          title: "Inicio",
          url: PAGES.HOME,
          iconSource: '/assets/icon/home.svg',
          active: this.iniciomenu
        },
        {
          title: "Empresas",
          url: PAGES.EMPRESAS,
          iconSource: '/assets/icon/negocio.svg',
          active: true
        },
        {
          title: "Bookings",
          url: PAGES.BOOKING,
          iconSource: '/assets/icon/bookings.svg',
          active: this.bookingmenu
        },
        {
          title: "Pesadas",
          url: PAGES.PESADAS,
          iconSource: '/assets/icon/balanza.svg',
          active: this.pesadasmenu
        },
        {
          title: "Contenedores",
          url: PAGES.VER_CTNEMPRESA,
          iconSource: '/assets/icon/contenedor.svg',
          active: this.ctnmenu
        },
        {
          title: "Fotos",
          url: PAGES.LISTADO_REGISTROS,
          iconSource: '/assets/icon/fotos.svg',
          active: this.fotosmenu
        },
        {
          title: "Trozas",
          url: PAGES.LISTADO_EVENTOSPESADAS,
          iconSource: '/assets/icon/troncos.svg',
          active: this.epmenu
        }
      ]
  }



  initializeApp(): void {
    // this.networkService.onNetworkChange().subscribe((status: ConnectionStatus) => {
    //   if (status === ConnectionStatus.Online) {
    //     this.offlineManager.checkForEvents().subscribe();
    //   }
    // });


    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      
      // this.notificationSetup();
    });
  }

 
  public async verconfig() {
    const alert = await this.alertController.create({
      header: 'Ingrese Contraseña:',
      inputs: [
        {
          name: 'clave',
          type: 'number',
          placeholder: ''
        }
      ],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {
            //console.log('Cancel clicked');
          }
        },
        {
          text: 'Validar',
          handler: async data => {
            if (data.clave = 6924) {
              const modal = await this.modalCtrl.create({
                component: ModalconfigPage,
                componentProps: {
                }
              }).then(async modalAfterCreate => {
                modalAfterCreate.present();

                const { data } = await modalAfterCreate.onDidDismiss();

              });
            } else {
              // invalid login
              return false;
            }
          }
        }
      ]
    });
    await alert.present();

  }

  ionRefresh(event) {
    //console.log('Pull Event Triggered!');
    this.sideMenu();
    setTimeout(() => {
      //console.log('Async operation has ended');

      //complete()  signify that the refreshing has completed and to close the refresher
      event.target.complete();
    }, 900);
  }
  ionPull(event) {
    //Emitted while the user is pulling down the content and exposing the refresher.
    //console.log('ionPull Event Triggered!');
  }
  ionStart(event) {
    //Emitted when the user begins to start pulling down.
    //console.log('ionStart Event Triggered!');
  }

  preconfig() {
    if(window.localStorage.getItem(DIAS_CONTENEDOR)){
      this.sideMenu();

    }else{
      window.localStorage.setItem(DIAS_CONTENEDOR, "30");
      window.localStorage.setItem(DIAS_BOOKING, "30");
      window.localStorage.setItem(CURRENT_PESADAS, "10");
      window.localStorage.setItem(DIAS_EVENTOSPESDA, "30");
  
      window.localStorage.setItem(DEFAULT_EMPRESA, "empresas1");
      window.localStorage.setItem(PAGE_INICIO, "home");
  
      window.localStorage.setItem(EMPRESAS_MENU, "true");
      window.localStorage.setItem(BOOKING_MENU, "true");
      window.localStorage.setItem(PESADAS_MENU, "true");
      window.localStorage.setItem(FOTOS_MENU, "true");
      window.localStorage.setItem(EP_MENU, "true");
      window.localStorage.setItem(INICIO_MENU, "true");
      window.localStorage.setItem(CTN_MENU, "true");
      window.localStorage.setItem(ESCANEO_CONTINUO, "false");
      window.localStorage.setItem(CONTROL_LOTE, "true");
      this.sideMenu();

    }
  }
}
