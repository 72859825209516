import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoadingService extends LoadingController {

  constructor() {
    super();
  }

  create(): Promise<HTMLIonLoadingElement> {
    return super.create({
      animated: true,
      spinner: 'dots',
      translucent: true
    });
  }
}
