import * as moment from 'moment';
import * as firebase from "firebase/app" 

import Timestamp = firebase.firestore.Timestamp;
export abstract class DateUtils {

    static isBefore(date: Date, before: Date): boolean {
        if(!date || !before) {
            return false;
        }

        return moment(date).isBefore(moment(before));
    }

    static getXDaysBefore(date: Date, days: any):Date {

        let dateTo = moment(date);
        let dateXDaysBefore = dateTo.subtract(days, 'd');

         return dateXDaysBefore.toDate();
    }

    static isBetween(date: Date, start: Date, end: Date): boolean {
        if (!date || !start || !end) {
            return false;
        }
        return moment(date).isBetween(moment(start), moment(end));
    }

    static newTimestamp(date?: Date): Timestamp {
        return Timestamp.fromDate(date ? date : new Date());
    }

}
