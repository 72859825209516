import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  Action,
  AngularFirestore,
  CollectionReference,
  DocumentChangeAction,
  DocumentReference,
  DocumentSnapshot,
  Query
} from '@angular/fire/firestore';
import { Entity, Timestamp } from '../models';
import firestore from 'firebase';
import * as _ from 'lodash';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { ErrorMessages } from '../error/error-messages';
import { Logger } from '../log/logger';
import { ApiError, PagedResult, QueryConfig } from './api.model';
import { Collection } from './collections';

//import WhereFilterOp = firestore.WhereFilterOp;

@Injectable({
  providedIn: 'root'
})
export class FirestoreService {

  constructor(
    protected errorMessages: ErrorMessages,
    protected afAuth: AngularFireAuth,
    protected store: AngularFirestore,
    protected logger: Logger
  ) {
  }

  protected mapError(error: any): void {
    // Get error from errorMessages, if not found get the firebase error or a generic message if not defined.
    const message = error.code ? this.errorMessages.getMessage(error.code) : error.message || this.errorMessages.getGenericMessage();
    this.logger.error(error);
    throw new ApiError(error, message);
  }

  query(col: Collection): FirestoreQuery {
    return FirestoreQuery.new(this).collection(col);
  }

  queryFrom(path: Array<string>, config?: QueryConfig): FirestoreQuery {
    return FirestoreQuery.of(this, path, config);
  }

  collection(col: Collection | string): FirestoreQuery {
    return FirestoreQuery.new(this).collection(col);
  }

  _add<T extends Entity>(entity: T, query: FirestoreQuery): Observable<T> {
    if (!entity.created_at) {
      entity.created_at = Timestamp.now();
    }
    return from(this.store.collection(query.path)
      .add(entity))
      .pipe(
        catchError(err => throwError(this.mapError(err))),
        switchMap((doc: DocumentReference) => from(doc.get())),
        map((snap: DocumentSnapshot<any>) => this.mapDocument(snap))
      );
  }

  _all<T extends Entity>(query: FirestoreQuery): Observable<Array<T>> {
    return this.store.collection(query.path)
      .snapshotChanges()
      .pipe(
        query.dontListenToChanges ? take(1) : tap(() => {
        }),
        catchError(err => throwError(this.mapError(err))),
        map(action => this.mapActionArray(action as Array<DocumentChangeAction<any>>))
      );
  }

  _paginated<T extends Entity>(query: FirestoreQuery): Observable<PagedResult<T>> {
    const queryConfig = query.getQueryConfig();
    return from(this.store.collection(query.path, ref => {
      return this.queryConfigToQuery(queryConfig, ref);
    })
      .snapshotChanges())
      .pipe(
        // Si no se necesita escuchar cambios, take(1) mata la subscripcion una vez resuelta.
        query.dontListenToChanges ? take(1) : tap(() => {
        }),
        catchError(err => throwError(this.mapError(err))),
        map(action => this.mapPagedResult(action as  Array<DocumentChangeAction<any>>, queryConfig))
      );
  }

  _allWith<T extends Entity>(query: FirestoreQuery): Observable<Array<T>> {
    const queryConfig = query.getQueryConfig();
    return from(this.store.collection<T>(query.path, ref => {
        return this.queryConfigToQuery(queryConfig, ref);
      })
        .snapshotChanges()
        .pipe(
          queryConfig.dontListenChanges ? take(1) : tap(() => {
          }),
          catchError(err => throwError(this.mapError(err))),
          map(action => this.mapActionArray<T>(action as Array<DocumentChangeAction<any>>))
        )
    );
  }

  _find<T extends Entity>(uid: string, query: FirestoreQuery): Observable<T> {
    return this.store.collection(query.path)
      .doc(uid)
      .snapshotChanges()
      .pipe(
        query.dontListenToChanges ? take(1) : tap(() => {
        }),
        catchError(err => throwError(this.mapError(err))),
        map(action => this.mapAction(action))
      );
  }

  _first<T extends Entity>(query: FirestoreQuery): Observable<T> {
    return this._allWith(query)
      .pipe(
        map(data => {
          return data.length > 0 ? data[0] as T : undefined;
        })
      );
  }

  _set<T extends Entity>(entity: T, query: FirestoreQuery): Observable<void> {
    const newEntity = {...entity};
    const uid = entity.id;
    newEntity.updated_at = Timestamp.now();
    delete newEntity.id;
    return from(this.store.collection(query.path).doc(uid)
      .set(newEntity))
      .pipe(
        catchError(err => throwError(this.mapError(err)))
      );
  }

  _update<T extends Entity>(entity: Partial<T>, query: FirestoreQuery): Observable<void> {
    const newEntity = {... entity };
    const uid = entity.id;
    newEntity.updated_at = Timestamp.now();
    delete newEntity.id;
    return from(this.store.collection<T>(query.path).doc(uid)
      .update(newEntity))
      .pipe(catchError(err => throwError(this.mapError(err))));
  }

  _delete(uid: string, query: FirestoreQuery): Observable<void> {
    return from(
      this.store.collection(query.path).doc(uid).delete()
    ).pipe(
      catchError(err => throwError(this.mapError(err)))
    );
  }

  protected queryConfigToQuery(options: QueryConfig, ref: CollectionReference | Query): CollectionReference | Query {
    let query: CollectionReference | Query = ref;
    if (options.whereClauses && options.whereClauses.length > 0) {
      for (const clause of options.whereClauses) {
        query = query.where(clause.fieldPath, clause.opStr, clause.value);
      }
    }
    // Order by
    if (options.field) {
      query = query.orderBy(options.field, options.reverse ? 'desc' : 'asc');
    }
    // A partir de tal valor?
    if (options.lastValue && !options.ignoreLastValue) {
      query = query.startAfter(options.lastValue);
    }
    // Limite de rows?
    if (options.limit) {
      query = query.limit(options.limit);
    }
    return query;
  }

  protected mapDocument<T extends Entity>(doc: DocumentSnapshot<any>): T {
    return this.newEntity(doc.id, doc.data());
  }

  /**
   * Constructor de entidad. Se puede sobrecargar.
   * @param id - id de la entidad (snapshot.id)
   * @param data - datos de la entidad (snapshot.data())
   */
  protected newEntity<T extends Entity>(id: any, data: any): T {
    return {
      id,
      ...data
    };
  }

  protected mapAction<T extends Entity>(action: Action<any>): T {
    return this.newEntity<T>(action.payload.id, action.payload.data());
  }

  protected mapActionArray<T extends Entity>(actions: Array<DocumentChangeAction<any>>): Array<T> {
    return actions.map(
      a => {
        return this.newEntity<T>(a.payload.doc.id, a.payload.doc.data());
      }
    );
  }

  protected mapPagedResult<T extends Entity>(actions: Array<DocumentChangeAction<any>>, lastQuery: QueryConfig): PagedResult<T> {
    const values = actions.map(
      a => {
        return this.newEntity<T>(a.payload.doc.id, a.payload.doc.data());
      });
    let lastValue;
    if (actions.length > 0) {
      lastValue = actions[actions.length - 1].payload.doc;
    }
    return {
      values,
      queryConfig: {
        ...lastQuery,
        lastValue
      }
    };
  }

}

export class FirestoreQuery {

  protected _path: Array<string>;
  protected _queryConfig: QueryConfig;

  set queryConfig(config: QueryConfig) {
    this._queryConfig = _.cloneDeep(config);
  }

  get queryConfig(): QueryConfig {
    return _.cloneDeep(this._queryConfig);
  }

  static of(service: FirestoreService, path: Array<string>, queryConfig: QueryConfig): FirestoreQuery {
    return new FirestoreQuery(service, path, queryConfig);
  }

  static new(service: FirestoreService): FirestoreQuery {
    return new FirestoreQuery(service);
  }

  constructor(
    protected service: FirestoreService,
    protected pathArray?: Array<string>,
    protected query?: QueryConfig,
  ) {
    if (!query) {
      this._queryConfig = {
        field: undefined,
        limit: null,
        reverse: true,
        prepend: undefined,
        lastValue: undefined,
        ignoreLastValue: true,
        dontListenChanges: false,
        whereClauses: []
      };
    } else {
      if (!query.whereClauses) {
        query.whereClauses = [];
      }
      this._queryConfig = query;
    }
    this._path = this.pathArray ? [... pathArray] : [];
  }

  collection(path: Collection| string): FirestoreQuery {
    this._path.push(path);
    return this;
  }

  id(id: string): FirestoreQuery {
    this._path.push(id);
    return this;
  }

  get dontListenToChanges(): boolean {
    return !!this._queryConfig.dontListenChanges;
  }

  get path(): string {
    if (this._path.length === 0) {
      throw new Error('Path is empty. FirestoreQuery.');
    }
    return this._path.join('/');
  }

  getPath(): Array<string> {
    return this._path;
  }

  getQueryConfig(): QueryConfig {
    return this.queryConfig;
  }
/*
  where(field: string, operator: WhereFilterOp, value: any): FirestoreQuery {
    this._queryConfig.whereClauses.push({
      fieldPath: field,
      opStr: operator,
      value
    });
    return this;
  }
*/
  orderBy(field: string, order: 'asc' | 'desc'): FirestoreQuery {
    this._queryConfig.field = field;
    this._queryConfig.reverse = order === 'desc';
    return this;
  }

  limit(limit: number): FirestoreQuery {
    this._queryConfig.limit = limit;
    return this;
  }

  reverse(reverse: boolean): FirestoreQuery {
    this._queryConfig.reverse = reverse;
    return this;
  }

  prepend(prepend: boolean): FirestoreQuery {
    this._queryConfig.prepend = prepend;
    return this;
  }

  lastValue(lastValue: any): FirestoreQuery {
    this._queryConfig.lastValue = lastValue;
    return this;
  }

  listen(listenChanges: boolean): FirestoreQuery {
    this._queryConfig.dontListenChanges = !listenChanges;
    return this;
  }

  ignoreLastValue(ignoreLastValue: boolean): FirestoreQuery {
    this._queryConfig.ignoreLastValue = ignoreLastValue;
    return this;
  }

  add<T extends Entity>(e: T): Observable<T> {
    return this.service._add<T>(e, this);
  }

  all<T extends Entity>(): Observable<Array<T>> {
    return this.service._all<T>(this);
  }

  paginated<T extends Entity>(): Observable<PagedResult<T>> {
    return this.service._paginated(this);
  }

  allWith<T extends Entity>(): Observable<Array<T>> {
    return this.service._allWith(this);
  }

  find<T extends Entity>(id: string): Observable<T> {
    return this.service._find(id, this);
  }

  first<T extends Entity>(): Observable<T> {
    return this.service._first(this);
  }

  set<T extends Entity>(entity: T): Observable<void> {
    return this.service._set(entity, this);
  }

  update<T extends Entity>(entity: Partial<T>): Observable<void> {
    return this.service._update(entity, this);
  }

  delete(uid: string): Observable<void> {
    return this.service._delete(uid, this);
  }

}
