import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CONFIG } from '../../../app.constants';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import { DateValidators } from '../../validators/validators';
import { ErrorMessages } from '../../../core/error';

export interface FilterBarEmitModel {
    start?: moment.Moment;
    end?: moment.Moment;
    text?: string;
}

export interface FilterBarErrorModel {
    errorType: FilterBarErrorType;
    errors: Array<string>;
}

export enum FilterBarErrorType {
    SEARCH_INPUT = 0,
    CALENDAR_SUBMIT = 1
}

@Component({
    selector: 'app-filter-bar',
    templateUrl: './filter-bar.component.html',
    styleUrls: ['./filter-bar.component.scss']
})
export class FilterBarComponent implements OnInit {

    endCalendarIcon: string = 'calendar';
    startCalendarIcon: string = 'calendar';

    form: FormGroup;
    readonly currentYear: number = moment().year();
    readonly dateFormat: string = CONFIG.FORMATS.DATE;
    /**
     * Cantidad de dias como intervalo maximo a elegir.
     */
    @Input() rangeDays: number = 60;
    @Input() calendarEnabled: boolean = true;
    @Input() readonly placeholder: string = 'Buscar';
    @Output() readonly buscar: EventEmitter<FilterBarEmitModel> = new EventEmitter();
    @Output() readonly errorFn: EventEmitter<FilterBarErrorModel> = new EventEmitter();

    constructor(protected formBuilder: FormBuilder, protected errorMessages: ErrorMessages) {
    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            start: ['', []],
            end: ['', []],
            text: ['', []]
        }, {
            validator: Validators.compose([
                DateValidators.dateLessThan('start', 'end', {dateLess: true}),
                // En rango de 360 dias.
                DateValidators.dateInRangeOf('start', 'end', this.rangeDays, {dateRange: true})
            ])
        });
        this.form.get('end').valueChanges.subscribe(val => {
            this.endCalendarIcon =  val ? 'close' : 'calendar';
        });
        this.form.get('start').valueChanges.subscribe(val => {
            this.startCalendarIcon =  val ? 'close' : 'calendar';
        });
    }

    emitBuscar($event?: Event): void {
        if (this.form.valid) {
            const formValue = this.form.value;
            const emitModel: FilterBarEmitModel = {};
            if (formValue.start) {
                emitModel.start = moment(formValue.start);
            }
            if (formValue.end) {
                emitModel.end = moment(formValue.end);
            }
            if (formValue.text) {
                emitModel.text = formValue.text;
            }
            this.buscar.emit(emitModel);
        } else {
            this.emitError(FilterBarErrorType.CALENDAR_SUBMIT);
        }
    }

    cleanInput(): void {
        this.form.get('text').setValue('');
    }

    emitError(errorType: FilterBarErrorType): void {
        const errors = this.form.errors;
        const errorMessages = [];
        if (errors['dateLess']) {
            errorMessages.push(this.errorMessages.getMessageWithParameters('dateLess', 'Fecha desde', 'fecha hasta'));
        }
        if (errors['dateRange']) {
            errorMessages.push(this.errorMessages.getMessageWithParameters('dateRange', this.rangeDays.toString(), 'días'));
        }
        const errorModel: FilterBarErrorModel = {
            errorType,
            errors: errorMessages
        };
        this.errorFn.emit(errorModel);
    }

    clearCalendar(formName: string): void {
        this.form.get(formName).setValue('');
    }

}
