import { FormArray, FormGroup } from '@angular/forms';
import firestore from 'firebase/app';
import { SubscriptionLike } from 'rxjs';
import * as firebase from "firebase/app" 

import Timestamp = firebase.firestore.Timestamp;

const IMAGE_EXTENSIONS = ['jpg', 'jpeg', 'gif', 'png'];

export abstract class ArrayUtils {

  static isNotEmpty(arr: Array<any>): boolean {
    return arr && arr.length > 0;
  }

  static isEmpty(arr: Array<any>): boolean {
    return !ArrayUtils.isNotEmpty(arr);
  }

  static removeAt(arr: Array<any>, position: number): Array<any> {
    if (arr) {
      return arr.splice(position, 1);
    }
    return arr;
  }
}

export function fileToArrayBuffer(fileUrl: string): any {
  return new Promise((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open('GET', fileUrl, true);
    request.responseType = 'blob';
    request.onload = () => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(request.response);
      reader.onload = (e: any) => {
        resolve(e.target.result);
      };
    };
    request.send();
  });
}

export function getFileExtension(fileName: string): string {
  if (!fileName) {
    throw new Error('No specified file name.');
  }
  const splitString = fileName.split('.');
  // Last index
  return splitString[splitString.length - 1];
}

export function isExtensionImage(ext: string): boolean {
  return IMAGE_EXTENSIONS.includes(ext);
}

export function getRandomId(): string {
  return Math.random().toString(36).substring(2);
}

export function bytesToMB(byteSize: number): number {
  return byteSize / 1e6;
}

export function getBufferedFile(file: any): Promise<any> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export enum SpecialKeysCodes {
  Backspace = 8,
  Tab = 9,
  End = 35,
  Home = 36,
  LeftArrow =  37,
  UpArrow =  38,
  RightArrow = 39,
  DownArrow =  40
}

export const isUrl = (s: string) => {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
  return regexp.test(s);
};

export const polluteForm = (group: FormGroup | FormArray) => {
  for (const control in group.controls) {
    if (group.controls.hasOwnProperty(control)) {
      const formControl = group.controls[control];
      if (formControl instanceof FormGroup || formControl instanceof FormArray) {
        // If this is another form group inside, then recursively pollute form
        polluteForm(formControl);
      } else {
        formControl.markAsDirty();
        formControl.markAsTouched();
        formControl.updateValueAndValidity();
      }
    }
  }
};

/**
 * Calcula el centro entre N coordenadas.
 */
export const calculateCentroid = (data: Array<{lat: number, lng: number}>): {lat: number, lng: number} => {
  if (!(data.length > 0)) {
    return null;
  }
  const num_coords = data.length;
  let X = 0;
  let Y = 0;
  let Z = 0;

  for (const point of data) {
    const latitude = point.lat * Math.PI / 180;
    const longitude = point.lng * Math.PI / 180;

    const a = Math.cos(latitude) * Math.cos(longitude);
    const b = Math.cos(latitude) * Math.sin(longitude);
    const c = Math.sin(latitude);

    X += a;
    Y += b;
    Z += c;
  }

  X /= num_coords;
  Y /= num_coords;
  Z /= num_coords;

  const lon = Math.atan2(Y, X);
  const hyp = Math.sqrt(X * X + Y * Y);
  const lat = Math.atan2(Z, hyp);

  const newX = (lat * 180 / Math.PI);
  const newY = (lon * 180 / Math.PI);
  return { lat: newX, lng: newY};
};
