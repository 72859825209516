import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastService {

  constructor(protected ctrl: ToastController) {
  }

  async error(message: string, duration?: number): Promise<any> {
    return this.ctrl.create({
      message,
      duration: duration || 3500,
      position: 'top',
      cssClass: 'error-toast',
      buttons: [
        { role: 'cancel', side: 'end', icon: 'clear' }
      ]
    });
  }

  async success(message: string, duration?: number): Promise<any> {
    return this.ctrl.create({
      message,
      cssClass: 'success-toast',
      duration: duration || 3500,
      position: 'top',
      animated: true,
      buttons: [
        { role: 'cancel', side: 'end', icon: 'clear' }
      ]
    });
  }

}
