import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class AppLoadingController extends LoadingController {

    constructor() {
        super();
    }

    async createDefault(): Promise<any> {
        const loader: any = await this.create({
            spinner: 'bubbles',
            showBackdrop: true
        });
        loader.dismissAfter = (timeout: number) => {
            setTimeout(() => {
                this.dismiss();
            }, timeout);
        };
        return loader;
    }
}
