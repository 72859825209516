import { Injectable } from '@angular/core';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { UploadMetadata } from '@angular/fire/storage/interfaces';
import * as firebase from "firebase/app" 

import Timestamp = firebase.firestore.Timestamp;

@Injectable()
export class StorageService {
    constructor(protected storage: AngularFireStorage) {

    }
    
    uploadImage(ref: string, arrayBuffer: any, metadata?: UploadMetadata): AngularFireUploadTask {
      return this.storage.ref(`${ref}`).put(arrayBuffer, metadata);
    }

    deleteFromURL(imageURL: string): Promise<void> {
        return this.storage.storage.refFromURL(imageURL).delete();
    }
}