import { Entity } from '../models';
import { WhereFilterOp } from '@firebase/firestore-types';

export class ApiError extends Error {
  error: any;
  // Custom Client side message.
  message: string;

  constructor(error: any, customMessage: string) {
    super();
    this.error = error;
    this.message = customMessage;
  }

}

export class AppError extends Error {
  message: string;

  constructor(public code: string, message?: string) {
    super();
    this.message = message;
  }
}

export interface WhereClause {
  fieldPath: string;
  opStr: WhereFilterOp;
  value: any;
}

export interface QueryConfig {
  field?: string; // field to orderBy
  limit?: number; // limit per query
  reverse?: boolean; // reverse order?
  prepend?: boolean; // prepend to source?
  lastValue?: any;
  ignoreLastValue?: boolean;
  dontListenChanges?: boolean;
  whereClauses?: Array<WhereClause>;
}

export interface PagedResult<T extends Entity> {
  values: Array<T>;
  queryConfig: QueryConfig;
}
