import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthService } from './api/auth.service';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { ErrorMessages } from './error';
import { StorageService } from './api/storage/storage.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Logger } from './log';
import { AngularFireDatabaseModule } from '@angular/fire/database';

import { FirestoreService } from './api/firestore.service';
/**
 * All services which have to have one and only one instance per application (singleton services) should be implemented here.
 * - Create a CoreModule with providers for the singleton services you load when the application starts.
 * - Import CoreModule in the root AppModule only. Never import CoreModule in any other module.
 * - Consider making CoreModule a pure services module with no declarations.
 */
@NgModule({
    imports: [
        CommonModule,
        AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
        AngularFirestoreModule.enablePersistence(),
        AngularFireModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireDatabaseModule,
        
    ],
    declarations: [],
    providers: [
        AuthService,
        AngularFireAuth,
        StorageService,
        ErrorMessages,
        Logger,
        FirestoreService
        
    ],
    exports: []
})
export class CoreModule {
    /* make sure CoreModule is imported only by one NgModule the AppModule */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
