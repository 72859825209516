import {
  Component, ElementRef, EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges, ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  CameraOptions,
  CameraPhoto,
  CameraPlugin,
  CameraResultType,
  CameraSource,
  FilesystemPlugin,
  Plugins
} from '@capacitor/core';
// import { ImagePicker, ImagePickerOptions } from '@ionic-native/image-picker/ngx';
import { Platform } from '@ionic/angular';
import { Logger } from '../../../core/log';
import { isUrl } from '../../utilities';
import { FileService } from '../../services/file.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { isEmpty, concat } from 'lodash';

@Component({
  selector: 'app-multiple-form-image',
  templateUrl: './multiple-form-image.component.html',
  styleUrls: ['./multiple-form-image.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultipleFormImageComponent),
      multi: true
    }
  ]
})
export class MultipleFormImageComponent implements OnInit, OnChanges, ControlValueAccessor {

  private PLACEHOLDER_IMAGE = 'assets/image/placeholder.png';

  @Input() slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  @Input() defaultImage: string | SafeResourceUrl = this.PLACEHOLDER_IMAGE;
  @Input() cameraOptions: CameraOptions;
  @Input() accept: string = 'image/*';

  @Output() readonly fileChanged: EventEmitter<any> = new EventEmitter();
  @Output() readonly fileDeleted: EventEmitter<any> = new EventEmitter();

  @ViewChild('inputFile', {static: true}) inputFile: ElementRef;

  selectedPhoto: CameraPhoto;
  isMobile: boolean;
  isDeleting: boolean = false;

  onChange: (v: any) => void;
  onTouched: () => void;
  disabled: boolean;

  currentsImages: Array<string | SafeResourceUrl> = [];

  protected camera: CameraPlugin;
  protected fileSystem: FilesystemPlugin;

  constructor(
    // protected imagePicker: ImagePicker,
    protected platform: Platform,
    protected fileService: FileService,
    protected sanitizer: DomSanitizer,
    protected logger: Logger
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async writeValue(value: any): Promise<void> {
    if (!isEmpty(value)) {
      for (const val of value) {
        if (typeof val === 'string' && !this.currentsImages.includes(val)) {
          this.currentsImages.push(val);
        }
      }
    }
  }

  ngOnInit(): void {
    this.isMobile = this.platform.is('capacitor');
    this.fileSystem = Plugins.Filesystem;
    this.camera = Plugins.Camera;
  }

  async onFileClick(): Promise<void> {
    this.onTouched();
    if (!this.disabled) {
      if (this.isMobile) {
        this.inputFile.nativeElement.click();
      }
    }
  }

  async onInputChange(event: any): Promise<void> {
    const files = event.target.files;
    if (!isEmpty(files)) {
      //this.onChange(files);
      this.fileChanged.emit(files);
      await this.displayImageFromFile(files);
    }
  }

  async displayImageFromFile(files: any[]): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      if (typeof files[i] !== 'string' && !isUrl(files[i])) {
        const extension = this.fileService.getFileExtension(files[i].name);
        if (!this.fileService.isExtensionImage(extension)) {
          return;
        }
        const contentType = `image/${extension}`;
        try {
          // Get buffered image, avoid using base64 because it kills cell phones on high res images.
          const buffer = await this.fileService.getBufferedFile(files[i]);
          // Blob to:
          // 1 - Display Image
          const blob = this.fileService.bufferToBlob(contentType, buffer);
          this.currentsImages.push(this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
        } catch (e) {
          this.logger.error(e);
        }
      } else {
        this.currentsImages.push(files[i]);
      }
    }
  }

  async takePhoto(): Promise<void> {
    const cameraOptions: CameraOptions = {
      ...this.getCameraOptions(),
      // Siempre es URI
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 60
    };
    try {
      this.selectedPhoto = await this.camera.getPhoto(cameraOptions);
      this.currentsImages.push(this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedPhoto && this.selectedPhoto.webPath));
      //this.onChange(this.selectedPhoto);
      this.fileChanged.emit([this.selectedPhoto]);
    } catch (e) {
      this.logger.debug('Usuario canceló selección de foto');
    }
  }

  async takeImage(): Promise<void> {
    const cameraOptions: CameraOptions = {
      ...this.getCameraOptions(),
      // Siempre es URI
      resultType: CameraResultType.Uri,
      source: CameraSource.Photos,
      quality: 60
    };
    try {
      this.selectedPhoto = await this.camera.getPhoto(cameraOptions);
      this.currentsImages.push(this.sanitizer.bypassSecurityTrustResourceUrl(this.selectedPhoto && this.selectedPhoto.webPath));
      //this.onChange([this.selectedPhoto]);
      this.fileChanged.emit([this.selectedPhoto]);
    } catch (e) {
      this.logger.error(e);
      this.logger.info('Usuario cancelo selección de foto');
    }
  }

  // async takeImages(): Promise<void> {
  //   const pickerOptions: ImagePickerOptions = {
  //     maximumImagesCount: 5
  //   };
  //   try {
  //     const images = await this.imagePicker.getPictures(pickerOptions);
  //     for (const image of images) {
  //       this.currentsImages.push(this.sanitizer.bypassSecurityTrustResourceUrl(image));
  //     }
  //     this.onChange(images);
  //   }catch (e) {
  //     this.logger.error(e);
  //     this.logger.info('Usuario cancelo Seleccion de foto');
  //   }
  // }

  protected getCameraOptions(): CameraOptions {
    return this.cameraOptions;
  }

  onDelete(index: number): void {
    this.isDeleting = true;
    const value = { index, currentsImages: [...this.currentsImages] };
    this.currentsImages.splice(index, 1);
    this.fileDeleted.emit(value);
  }

}
