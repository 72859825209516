import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../core/api/auth.service';
import { auth, User } from 'firebase/app';
import { Usuario } from '../../core/models';
import firebase from 'firebase/app';
import { async } from '@angular/core/testing';

@Injectable({
  providedIn: 'root'
})
export class UsersService implements OnInit {
  user: User;
  userColl: Usuario;
  private usuarioCollection: AngularFirestoreCollection<Usuario>;

  private usuarios: any;
  private usuario: Observable<Usuario[]>;

  constructor(private db: AngularFirestore, public auth: AuthService
  ) {
    this.usuarioCollection = db.collection<Usuario>('users');
    this.getusuarios();
  }
  ngOnInit() {
    this.getusuarios();
  }

  getusuarios() {
    this.usuarios = this.usuarioCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const doc_id = a.payload.doc.id;
         return  { doc_id, ...data };
        });
      })
    );
  }
  getusuarioLogin() {
    this.user = firebase.auth().currentUser;
    // this.getDatosCompletos();
  }

  getusuario(id) {
    return this.usuarioCollection.doc<Usuario>(id).valueChanges();
  }

  updateusuario(usuario: Usuario, id: string) {
    return this.usuarioCollection.doc(id).update(usuario);
  }

  addusuario(usuario: Usuario) {
    //console.log(usuario);
    return this.usuarioCollection.add(usuario);
  }

  removeusuario(id) {
    return this.usuarioCollection.doc(id).delete();
  }

  getDatosCompletos(e) {
    this.usuario.subscribe(a => {
      for (let i in a) {
        if (a[i].email === e) {
          return a[i];
        }
      }
    })

  }
  getEmpresasUsuario(id) {
    //return this.usuarioCollection.doc<Usuario>(id).collection<Usuario>('subusuarios').valueChanges()

    return this.usuarioCollection.doc<Usuario>(id).collection<Usuario>('empresas', ref => ref.orderBy("nombre")).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const doc_id = a.payload.doc.id;
          return { doc_id, ...data };
        })
      })
    );
  }
  getModulesUsuario(id) {
    //return this.usuarioCollection.doc<Usuario>(id).collection<Usuario>('subusuarios').valueChanges()

    return this.usuarioCollection.doc<Usuario>(id).collection<Usuario>('modulesMenu', ref => ref.orderBy("title")).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const doc_id = a.payload.doc.id;
          return { doc_id, ...data };
        })
      })
    );
  }

  addusuariousuario(id, usuario: Usuario) {
    //console.log(id);
    return this.usuarioCollection.doc(id).collection('subusuarios').add(usuario)

  }

}
