import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { tipoeventoctn } from '../../../../core/models';
import { ApiService } from '../../../services/api.service';
//import { DataProvider } from '../../services/data-service';

@Component({
  selector: 'app-modalselectevento',
  templateUrl: './modalselectevento.page.html',
  styleUrls: ['./modalselectevento.page.scss'],
})
export class ModalSelectEventoPage implements OnInit {

  eventos: Array<tipoeventoctn>;
       
  constructor(
    private modalController: ModalController,
    public formBuilder: FormBuilder,
    protected api: ApiService,
    public alertCtrl: AlertController) {
      //console.log(this.empresa)
      
  }

  ionViewDidLoad() {
    //console.log('ionViewDidLoad ModalPage');
  }

  ngOnInit() {
    this.cargatiposEventos()
  }

  select(e) {
    this.modalController.dismiss({
      'evento': e.valor
      ,
      'tipo':e.nombre
    });
  }


  closeModal() {  
      //console.log('Lo cierro!')  
     this.modalController.dismiss({
      }); 

  }

  cargatiposEventos() {
    //console.log("items consolidados");
    this.api.getTiposEventosCTN().subscribe(data => {
      if (data) {
        this.eventos = [];

        for (let i in data) {
          this.eventos.push(data[i]);
        }
        console.log(this.eventos);
      }
    }, err => {

      //console.log(err);
    });
  }

}
