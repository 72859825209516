import { Component, OnInit, Input } from '@angular/core';
import { PesadasGaudi, Pesadas } from '../../../core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiserviceService } from '../../services/apiservice.service';
import { EmpresasService } from '../../services/empresas.service';
import { DIAS_CONTENEDOR, ESCANEO_CONTINUO,CONTROL_LOTE, DIAS_BOOKING,INICIO_MENU, CURRENT_PESADAS, DEFAULT_EMPRESA,PAGE_INICIO, DIAS_EVENTOSPESDA, EMPRESAS_MENU, BOOKING_MENU, PESADAS_MENU, FOTOS_MENU, EP_MENU, CTN_MENU } from '../../constants/local-storage';
import {
  BarcodeScannerOptions,
  BarcodeScanner
} from "@ionic-native/barcode-scanner/ngx";
import { CURRENT_CTN, TOKEN_NOTIFICATION } from '../../../shared/constants/local-storage';
import { ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from '@capacitor/core';

const { PushNotifications } = Plugins;
@Component({
  selector: 'app-modalconfig',
  templateUrl: './modalconfig.page.html',
  styleUrls: ['./modalconfig.page.scss'],
})
export class ModalconfigPage implements OnInit {
  registroForm: FormGroup;
  empresas: Array<{ nombre: string, nombresistema: string }>;
  tokenlocals: Array<{ id: string, colleccion: string }>;
  empresaslist: any;
  token: PushNotificationToken;
  notification: boolean = false;
  tokenls = "";
  public empresasmenu ;
  public bookingmenu ;
  public pesadasmenu ;
  public fotosmenu ;
  public epmenu ;
  public iniciomenu ;
  public ctnmenu ;
  public escaneocontinuo ;
  public ctrllote ;

  constructor(
    private toastrController: ToastController,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    public apiservice: ApiserviceService,
    public empservice: EmpresasService,
    private db: AngularFirestore) {


    this.registroForm = this.formBuilder.group({
      // empdefaultpesadas: ['', Validators.compose([Validators.minLength(5)])],
      diascontenedores: ['', Validators.compose([Validators.maxLength(3), Validators.max(300), Validators.minLength(1)])],
      diasbooking: ['', Validators.compose([Validators.maxLength(3), Validators.max(300), Validators.minLength(1)])],
      diaspesadas: ['', Validators.compose([Validators.maxLength(3), Validators.max(300), Validators.minLength(1)])],
      diasep: ['', Validators.compose([Validators.maxLength(3), Validators.max(300), Validators.minLength(1)])],
      selectempresa: ['', Validators.compose([Validators.required])],
      pageinicio: ['', Validators.compose([Validators.required])],
   

    });
  }

  ngOnInit() {
    //console.log( window.localStorage.getItem(DIAS_EVENTOSPESDA));

    this.registroForm.controls['diascontenedores'].setValue(window.localStorage.getItem(DIAS_CONTENEDOR));
    this.registroForm.controls['diasbooking'].setValue(window.localStorage.getItem(DIAS_BOOKING));
    this.registroForm.controls['diaspesadas'].setValue(window.localStorage.getItem(CURRENT_PESADAS));
    this.registroForm.controls['diasep'].setValue(window.localStorage.getItem(DIAS_EVENTOSPESDA));
    this.registroForm.controls['selectempresa'].setValue(window.localStorage.getItem(DEFAULT_EMPRESA));
    this.registroForm.controls['pageinicio'].setValue(window.localStorage.getItem(PAGE_INICIO));

    this.empresasmenu = (window.localStorage.getItem(EMPRESAS_MENU) == 'true');
    this.bookingmenu = (window.localStorage.getItem(BOOKING_MENU) == 'true');
    this.pesadasmenu = (window.localStorage.getItem(PESADAS_MENU) == 'true');
    this.fotosmenu = (window.localStorage.getItem(FOTOS_MENU) == 'true');
    this.epmenu = (window.localStorage.getItem(EP_MENU) == 'true');
    this.iniciomenu = (window.localStorage.getItem(INICIO_MENU) == 'true');
    this.ctnmenu = (window.localStorage.getItem(CTN_MENU) == 'true');
    this.ctrllote = (window.localStorage.getItem(CONTROL_LOTE) == 'true');
    this.escaneocontinuo = (window.localStorage.getItem(ESCANEO_CONTINUO) == 'true');

  }


 

  checked(checked) {
    if (checked === "empresasmenu") {
      this.empresasmenu = !this.empresasmenu;
    }
    if (checked === "bookingmenu") {
      this.bookingmenu = !this.bookingmenu;
    }
    if (checked === "pesadasmenu") {
      this.pesadasmenu = !this.pesadasmenu;
    }
    if (checked === "fotosmenu") {
      this.fotosmenu = !this.fotosmenu;
    }
    if (checked === "epmenu") {
      this.epmenu = !this.epmenu;
    }
    if (checked === "iniciomenu") {
      this.iniciomenu = !this.iniciomenu;
    }
    if (checked === "ctnmenu") {
      this.ctnmenu = !this.ctnmenu;
    }
    if (checked === "escaneocontinuo") {
      this.escaneocontinuo = !this.escaneocontinuo;
    }
    if (checked === "ctrllote") {
      this.ctrllote = !this.ctrllote;
    }
  }



  guardar() {
    //console.log(this.registroForm.value.diasep);
    // window.localStorage.setItem(DEFAULT_EMPRESA, this.registroForm.value.empDefectopesads);
    window.localStorage.setItem(DIAS_CONTENEDOR, this.registroForm.value.diascontenedores);
    window.localStorage.setItem(DIAS_BOOKING, this.registroForm.value.diasbooking);
    window.localStorage.setItem(CURRENT_PESADAS, this.registroForm.value.diaspesadas);
    window.localStorage.setItem(DIAS_EVENTOSPESDA, this.registroForm.value.diasep);

    window.localStorage.setItem(DEFAULT_EMPRESA, this.registroForm.value.selectempresa);
    window.localStorage.setItem(PAGE_INICIO, this.registroForm.value.pageinicio);

    window.localStorage.setItem(EMPRESAS_MENU, this.empresasmenu+""+"");
    window.localStorage.setItem(BOOKING_MENU, this.bookingmenu+"");
    window.localStorage.setItem(PESADAS_MENU, this.pesadasmenu+"");
    window.localStorage.setItem(FOTOS_MENU, this.fotosmenu+"");
    window.localStorage.setItem(EP_MENU, this.epmenu+"");
    window.localStorage.setItem(INICIO_MENU, this.iniciomenu+"");
    window.localStorage.setItem(CTN_MENU, this.ctnmenu+"");
    window.localStorage.setItem(ESCANEO_CONTINUO, this.escaneocontinuo+"");
    window.localStorage.setItem(CONTROL_LOTE, this.ctrllote+"");


    this.closeModal();
  }

  closeModal() {
    this.modalController.dismiss({
    });
  }

}
