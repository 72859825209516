import { Component, OnInit, Input } from '@angular/core';
import { PesadasGaudi, Pesadas, Contenedor } from '../../../core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiserviceService } from '../../services/apiservice.service';
import { EmpresasService } from '../../services/empresas.service';
import { DIAS_CONTENEDOR, DIAS_BOOKING, CURRENT_PESADAS, DEFAULT_EMPRESA } from '../../constants/local-storage';
import { CURRENT_CTN, TOKEN_NOTIFICATION } from '../../../shared/constants/local-storage';
import { ModalController, ToastController, AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-modalinfoctn',
  templateUrl: './modalinfoctn.page.html',
  styleUrls: ['./modalinfoctn.page.scss'],
})
export class ModalinfoctnPage implements OnInit {
  @Input() contenedor: Contenedor
  precintoGuardado = "";
  bultosGuardado = "";
  registroForm: FormGroup;
  empresas: Array<{ nombre: string, nombresistema: string }>;
  tokenlocals: Array<{ id: string, colleccion: string }>;
  empresaslist: any;
  notification: boolean = false;
  tokenls = "";
  public fumigado = false;
  public consolidado = false;
  public fumigadoI = false;
  public consolidadoI = false;
  public vpinchazootro = false;
  public ctnliberado = false;
  public fechaLiberacion = "";

  temas = ["Información", "Consolidado", "Desconsolidado", "Precinto", "Bultos", "Payload", "Trasbordo", "Descarga", "Estado", "Reclamo", "Kilos", "Numero", "Carga", "Otro"];

  constructor(
    private toastrController: ToastController,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    public apiservice: ApiserviceService,
    public alertController: AlertController,
    public empservice: EmpresasService,
    protected api: ApiService,
    private db: AngularFirestore) {
    this.precintoGuardado = "";
    this.bultosGuardado = "";

    this.registroForm = this.formBuilder.group({
      // empdefaultpesadas: ['', Validators.compose([Validators.minLength(5)])],
      precinto: ['', Validators.compose([Validators.required])],
      bultos: ['', Validators.compose([Validators.required])],
      ptipo: ['', Validators.compose([Validators.required])],
      pdestino: ['', Validators.compose([Validators.required])],
      pdestinootro: ['', Validators.compose([Validators.required])]

    });
  }

  ngOnInit() {
    this.registroForm.controls['precinto'].setValue(this.contenedor['consolidado-precinto']);
    this.registroForm.controls['bultos'].setValue(this.contenedor.bultos);
    if (this.contenedor.fumigado != null && this.contenedor.fumigado != '0000-00-00 00:00:00') {
      this.fumigadoI = true;
    }
    if (this.contenedor['consolidado-fecha-fin'] != null) {
      this.consolidadoI = true;
    }
    if (this.contenedor['consolidado-fumigado-libera'] != null) {
      console.log(this.contenedor['consolidado-fumigado-libera']);
      this.ctnliberado = true;
      this.fechaLiberacion=this.contenedor['consolidado-fumigado-libera'];
    }
    //console.log(this.fumigadoI + " " + this.consolidadoI);
  }

  async guardarConsolidado() {
    let tipo = "consolidado";
    if (this.contenedor.tipo == "ctn-cargado") { tipo = "desconsolidado" };
    const alert = await this.alertController.create({
      header: 'Contenedor ' + tipo + '?',
      message: this.contenedor.numeroctn + '?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            let fecha = new Date().toISOString();
            //let fecha = date.getFullYear().toString()+"-"+(date.getMonth()+1).toString()+"-"+date.getDate().toString()+" "+date.getHours().toString()+":"+date.getMinutes().toString()+":"+date.getSeconds().toString();
            //let logscan = { nombreevento: "consolidado-fin", fecha: fecha, contenedor: ctn.numeroctn, lote: "Terminar" + "", meta: "" };
            this.contenedor['consolidado-fecha-fin'] = fecha;
            this.consolidadoI = true;
            this.postEventos("Terminar", tipo + "-fin");
          }
        }
      ]
    });
    await alert.present();
  }

  async guardarFumigado() {
    const alert = await this.alertController.create({
      header: 'Contenedor Fumigado?',
      message: this.contenedor.numeroctn + '?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            let fecha = new Date().toISOString();
            //let fecha = date.getFullYear().toString()+"-"+(date.getMonth()+1).toString()+"-"+date.getDate().toString()+" "+date.getHours().toString()+":"+date.getMinutes().toString()+":"+date.getSeconds().toString();
            //let logscan = { nombreevento: "consolidado-fin", fecha: fecha, contenedor: ctn.numeroctn, lote: "Terminar" + "", meta: "" };
            this.contenedor.fumigado = fecha;
            this.fumigadoI = true;
            this.postEventos("", "consolidado-fumigado");
          }
        }
      ]
    });
    await alert.present();
  }

  async guardarLiberacion() {
    const alert = await this.alertController.create({
      header: 'Contenedor Liberado?',
      message: this.contenedor.numeroctn + '?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            let fecha = new Date().toISOString();
            //let fecha = date.getFullYear().toString()+"-"+(date.getMonth()+1).toString()+"-"+date.getDate().toString()+" "+date.getHours().toString()+":"+date.getMinutes().toString()+":"+date.getSeconds().toString();
            //let logscan = { nombreevento: "consolidado-fin", fecha: fecha, contenedor: ctn.numeroctn, lote: "Terminar" + "", meta: "" };
            this.contenedor.liberado = true;
            this.contenedor.fecha_liberado=fecha;  
            this.ctnliberado = true;
            this.postEventos("", "consolidado-liberado");
          }
        }
      ]
    });
    await alert.present();
  }

  async guardarBultos() {
    const bultos = this.registroForm.value.bultos;
    const alert = await this.alertController.create({
      header: 'Guardar bultos?',
      message: bultos + '?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.contenedor.bultos = bultos;
            this.postEventos(bultos, "consolidado-bultos");
          }
        }
      ]
    });
    await alert.present();
  }

  async guardarPrecinto() {
    const precinto = this.registroForm.value.precinto;
    const alert = await this.alertController.create({
      header: 'Precinto provisorio?',
      message: precinto + '?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.contenedor['consolidado-precinto'] = precinto;
            this.postEventos(precinto, "consolidado-precinto");
          }
        }
      ]
    });
    await alert.present();
  }

  cambioDestino() {
    const destino = this.registroForm.value.pdestino;
    if (destino == "Otro") {
      this.vpinchazootro = true;
    } else {
      this.vpinchazootro = false;
    }
  }

  async guardarpinchazo() {
    const destino = this.registroForm.value.pdestino;
    const tipo = this.registroForm.value.ptipo;
    const alert = await this.alertController.create({
      header: 'Guardar bultos?',
      message: tipo+" "+destino + '?',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            //console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: () => {
            this.postEventos(destino, tipo);
          }
        }
      ]
    });
    await alert.present();
  }


  async postEventos(lote, tipo) {
    let fecha = new Date().toISOString();
    //let fecha = date.getFullYear().toString()+"-"+(date.getMonth()+1).toString()+"-"+date.getDate().toString()+" "+date.getHours().toString()+":"+date.getMinutes().toString()+":"+date.getSeconds().toString();
    let logscan = { nombreevento: tipo, fecha: fecha, contenedor: this.contenedor.numeroctn, lote: lote + "", meta: "" };


    /*  this.apiservice.postEvento(logscan).subscribe(data => {
        //console.log(data);
        this.toastrController.create({
          message: tipo + ' Registrado',
          duration: 1500
        }).then(res => {
          res.present()
        })
  
      });*/

    this.api.guardarEventoContenedor(logscan).subscribe(data => {

      this.toastrController.create({
        message: 'Registrado',
        duration: 1500
      }).then(res => {
        res.present()
      })
      //this.cargaEventost();
    }, err => {

      this.toastrController.create({
        message: 'Error en Registro',
        duration: 1500
      }).then(res => {
        res.present()
      })
    });

  }

  closeModal() {
    this.modalController.dismiss({
    });
  }


}
