import { AngularFirestore, AngularFirestoreCollection, DocumentReference } from '@angular/fire/firestore';
import firestore from 'firebase';
import { LOTE_BORRAR, LOTE_GUARDAR, PESADA_BORRAR, PESADA_GUARDAR } from '../../app.constants';
import { EventoPost, imagenContenedor, preAnuncio} from '../../core/models';
import { EVENTOS_LOTES, EVENTOS_PESADAS } from '../constants/local-storage';
import { ErrorMessages } from '../../core/error';
import { Logger } from '../../core/log';
import { ContenedorLoteService } from './contenedor-lote.service';
import { AnuncioService } from './anuncio.service';
import { TipoEventosService } from './tiposeventos.service';
import { FotoService } from './foto.service';
import { OfflineManagerService } from './offline-manager.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NetworkService, ConnectionStatus } from './network.service';
import { Storage } from '@ionic/storage';
import { Observable, from, throwError } from 'rxjs';
import { PesadaService } from './pesada.service';
import { LugarService } from './lugar.service';
import * as firebase from "firebase/app" 

import Timestamp = firebase.firestore.Timestamp;
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  collection: AngularFirestoreCollection<any>;
  collectionName: string;

  constructor(
      private http: HttpClient,
      private networkService: NetworkService,
      private offlineManager: OfflineManagerService,
      protected pesadaService: PesadaService,
      protected lugarService:LugarService,
      protected contenedorService: ContenedorLoteService,
      protected anuncioService: AnuncioService,
      protected fotoservice: FotoService,
      protected tipoEventosService:TipoEventosService,
      private storage: Storage,
      protected store: AngularFirestore,
      protected logger: Logger,
      protected errorMessages: ErrorMessages) {
    this.collectionName = 'trozas';
    this.collection = store.collection<EventoPost>(this.collectionName);
  }

  guardarEventoPesada(pesada: EventoPost): Observable<EventoPost> {
    // this.setEventoPesada(pesada);
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.offlineManager.storeRequest('', PESADA_GUARDAR, pesada)) as Observable<EventoPost>;
    // }
    return this.pesadaService.add(pesada);
  }

  borrarEventoPesada(pesada: EventoPost, index?: number): Observable<any> {
    const uid = pesada.id;
    // if (index >= 0) {
    //   this.deleteEventoFromStorage(index);
    //   this.offlineManager.removeRequest(+pesada.lote, PESADA_GUARDAR);
    // }
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline && uid) {
    //   return from(this.offlineManager.storeRequest('', PESADA_BORRAR, uid));
    // }
    // if (uid) {
    //   return this.pesadaService.delete(String(uid));
    // } else {
    //   return from([]);
    // }
    return this.pesadaService.delete(String(uid));
  }

  getEventosPesadas(boleta: any): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.getEventosPesadaFromStorage());
    // }
    return this.pesadaService.allWith({
      limit: 100,
      whereClauses: [
        { fieldPath: 'numero', opStr: '==', value: boleta }
      ]
    });
  } 

  getLugaresDescarga(): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.getEventosPesadaFromStorage());
    // }
    return this.lugarService.allWith({    
      limit: 200,
      whereClauses: [
       
      ]  
    });
  } 
  
  getEventosPesadasall(): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.getEventosPesadaFromStorage());
    // }
    return this.pesadaService.allWith({
      limit: 200,
      whereClauses: [
       
      ]
    });
  }


  async setEventoPesada(registro: EventoPost): Promise<void> {
    let eventos = await this.storage.get(EVENTOS_PESADAS);
    if (eventos && eventos.length > 0) {
      eventos.push(registro);
    } else {
      eventos = [registro];
    }
    await this.storage.set(EVENTOS_PESADAS, eventos);
  }

  async getEventosPesadaFromStorage(): Promise<void> {
    return await this.storage.get(EVENTOS_PESADAS);
  }

  async deleteEventoFromStorage(index: number | string): Promise<any> {
    const eventos = await this.storage.get(EVENTOS_PESADAS);
    if (eventos && eventos.length > 0) {
      eventos.splice(index, 1);
      await this.storage.set(EVENTOS_PESADAS, eventos);
    }
  }

  // CONTENEDORES LOTES EVENTOS :-)
  guardarEventoContenedor(lote: EventoPost): Observable<EventoPost> {
    // this.setEventoContenedor(lote);
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.offlineManager.storeRequest('', LOTE_GUARDAR, lote)) as Observable<EventoPost>;
    // }
    return this.contenedorService.add(lote);
  }

    // CONTENEDORES LOTES EVENTOS :-)
    guardarpreAnuncio(anuncio: preAnuncio): Observable<preAnuncio> {
      // this.setEventoContenedor(lote);
      // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
      //   return from(this.offlineManager.storeRequest('', LOTE_GUARDAR, lote)) as Observable<EventoPost>;
      // }
      return this.anuncioService.add(anuncio);
    }

  borrarEventoContenedor(lote: EventoPost, index?: number): Observable<any> {
    const uid = lote.id;
    // if (index >= 0) {
    //   this.deleteEventoContenedorFromStorage(index);
    //   this.offlineManager.removeRequest(+lote.lote, LOTE_GUARDAR);
    // }
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline && uid) {
    //   return from(this.offlineManager.storeRequest('', LOTE_BORRAR, uid));
    // }
    // if (uid) {
    //   return this.contenedorService.delete(String(uid));
    // } else {
    //   return from([]);
    // }
    return this.contenedorService.delete(String(uid));
  }

  getEventosContenedor(boleta: any): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.getEventosContenedorFromStorage());
    // }
    return this.contenedorService.allWith({
      limit: 100,
      whereClauses: [
        { fieldPath: 'contenedor', opStr: '==', value: boleta }
      ]
    });
  }

  getPreAnuncios(reg): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.getEventosContenedorFromStorage());
    // }
    return this.anuncioService.allWith({
      limit: 100,
      whereClauses: [
        { fieldPath: 'gaudi_registrado', opStr: '==', value: reg }
      ]
    });
  }

  async setEventoContenedor(registro: EventoPost): Promise<void> {
    let eventos = await this.storage.get(EVENTOS_LOTES);
    if (eventos && eventos.length > 0) {
      eventos.push(registro);
    } else {
      eventos = [registro];
    }
    await this.storage.set(EVENTOS_LOTES, eventos);
  }

  async getEventosContenedorFromStorage(): Promise<void> {
    return await this.storage.get(EVENTOS_LOTES);
  }

  async deleteEventoContenedorFromStorage(index: number | string): Promise<any> {
    const eventos = await this.storage.get(EVENTOS_LOTES);
    if (eventos && eventos.length > 0) {
      eventos.splice(index, 1);
      await this.storage.set(EVENTOS_LOTES, eventos);
    }
  }

  
  borrarFoto(fotodid: imagenContenedor, index?: number): Observable<any> {
    const uid = fotodid.id;
    // if (index >= 0) {
    //   this.deleteEventoContenedorFromStorage(index);
    //   this.offlineManager.removeRequest(+lote.lote, LOTE_GUARDAR);
    // }
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline && uid) {
    //   return from(this.offlineManager.storeRequest('', LOTE_BORRAR, uid));
    // }
    // if (uid) {
    //   return this.contenedorService.delete(String(uid));
    // } else {
    //   return from([]);
    // }
    return this.fotoservice.delete(String(uid));
  }

  getFotosContenedor(boleta: any): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.getEventosContenedorFromStorage());
    // }
    return this.fotoservice.allWith({
      limit: 100,
      whereClauses: [
        { fieldPath: 'titulo', opStr: '==', value: boleta }
      ]
    });
  }

  getTiposEventosCTN(): Observable<any> {
    // if (this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline) {
    //   return from(this.getEventosContenedorFromStorage());
    // }
    return this.tipoEventosService.allWith({
      limit: 100,
      whereClauses: [
      ]
    });
  }

}
