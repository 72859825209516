import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Observable, from, of, forkJoin } from 'rxjs';
import { switchMap, finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { LOTE_BORRAR, LOTE_GUARDAR, PESADA_BORRAR, PESADA_GUARDAR } from '../../app.constants';
import { EVENTOS_LOTES, EVENTOS_PESADAS } from '../constants/local-storage';
import { ContenedorLoteService } from './contenedor-lote.service';
import { PesadaService } from './pesada.service';

const STORAGE_REQ_KEY = 'storageRequest';

interface StoredRequest {
  url: string;
  type: string;
  data: any;
  time: number;
  id: string;
  operation?: (data: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class OfflineManagerService {

  constructor(
      private storage: Storage,
      private http: HttpClient,
      private toastController: ToastController,
      protected pesadaService: PesadaService,
      protected contenedorLoteService: ContenedorLoteService) { }

  checkForEvents(): Observable<any> {
    return from(this.storage.get(STORAGE_REQ_KEY)).pipe(
        switchMap( (storedOperations) => {
          const storedObj = JSON.parse(storedOperations);
          if (storedObj && storedObj.length > 0) {
            return this.sendRequests(storedObj).pipe(
                finalize(async () => {
                  const toast = this.toastController.create({
                    message: `Local data succesfully synced to API!`,
                    duration: 3000,
                    position: 'top'
                  });
                  await toast.then(t => t.present());

                  await this.storage.remove(STORAGE_REQ_KEY);
                  await this.storage.remove(EVENTOS_PESADAS);
                  await this.storage.remove(EVENTOS_LOTES);
                })
            );
          } else {
            //console.log('no local events to sync');
            return of(false);
          }
        })
    );
  }

  async storeRequest(url, type, data): Promise<any> {
    const toast = this.toastController.create({
      message: `Your data is stored locally because you seem to be offline.`,
      duration: 2500,
      position: 'top'
    });
    await toast.then(t => t.present());

    const action: StoredRequest = {
      url,
      type,
      data,
      time: new Date().getTime(),
      id: Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5)
    };
    // https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript

    return this.storage.get(STORAGE_REQ_KEY).then(storedOperations => {
      let storedObj = JSON.parse(storedOperations);

      if (storedObj) {
        storedObj.push(action);
      } else {
        storedObj = [action];
      }
      // Save old & new local transactions back to Storage
      return this.storage.set(STORAGE_REQ_KEY, JSON.stringify(storedObj));
    });
  }

  async removeRequest(numero: number, type: string): Promise<void> {
    const requests = await this.storage.get(STORAGE_REQ_KEY);
    const requestArray = JSON.parse(requests);
    if (requestArray && requestArray.length > 0) {
      const elem = requestArray.filter(r => r.data.type === type && + +r.data.lote === numero).pop();
      const index = requestArray.indexOf(elem);
      if (index) {
        requestArray.splice(index, 1);
        await this.storage.set(STORAGE_REQ_KEY, requestArray);
      }
    }
  }

  sendRequests(operations: StoredRequest[]) {
    const obs = [];
    let opObservable;
    for (const op of operations) {
      if (op.type === PESADA_GUARDAR) {
        opObservable = this.pesadaService.add(op.data);
      } else if (op.type === PESADA_BORRAR) {
        opObservable = this.pesadaService.delete(op.data);
      } else if (op.type === LOTE_GUARDAR) {
        opObservable = this.contenedorLoteService.add(op.data);
      } else if (op.type === LOTE_BORRAR) {
        opObservable = this.contenedorLoteService.delete(op.data);
      }
      obs.push(opObservable);
    }
    // Send out all local events and return once they are finished
    return forkJoin(obs);
  }
}
