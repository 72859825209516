import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-popover-progress',
    templateUrl: './popover-progress.component.html',
    styleUrls: ['./popover-progress.component.scss']
})
export class PopoverProgressComponent implements OnInit {

    @Input() value: number = 57;
    @Input() max: number = 100;
    @Input() label: string = '';
    @Output() readonly cancel: EventEmitter<any> = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    onCancel(): void {
        this.cancel.emit();
    }

}
