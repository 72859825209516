//import { Timestamp } from 'firebase/firestore';


export interface Entity {
  id?: string;
  created_at?: Timestamp;
  updated_at?: Timestamp;
}

import firestore from 'firebase';

import * as firebase from "firebase/app" 

export import Timestamp = firebase.firestore.Timestamp; 

export import GeoPoint = firebase.firestore.GeoPoint;

export interface Archivo extends Entity {
  url: string;
  storageName: string;
 // agregado: Timestamp;
  user: { uid: string, displayName: string, email: string, photoURL: string; };
  nombre: string;
  descripcion?: string;
  uploadState: UploadState;
}

export interface Registro extends Entity {
  titulo?:string;
  comentarios?: string;
  downloadURL: string;
  path?:string;
  contenedor:Contenedor;
}

export enum UploadState {
  UPLOADED = 0,
  UPLOADING = 1,
  UPLOADING_FIRST = 2,
  ERROR = 3
}

export interface Contenedor extends Entity{
    id: string;
    added: string;
    fecha: string;
    matricula: string;
    idcliente: string;
    empresa: string;
    nombreproducto: string;
    tipo: string;
    booking:string;
    bookingnumero:string;
    numeroctn: string;
    tara: string;
    payload: string;
    remito: string; 
    precinto: string;
    fumigado: string;
    consolidado: string;
    bultos: string;
    fechasalida: string;
    fechahora_pesada: string;
    matriculasalida: string;
    consolidadocantitems: string;
    consolidadofechafin: any
    consolidadofumigadolibera:any;
    booking_consolidar?: string;
    liberado?:boolean;
    fecha_liberado?:string;
  } 

  export interface preAnuncio extends Entity{
    fecha: string;
    matricula?: string;
    empresa?: string;
    nombreproducto?: string;
    booking?:string;
    bookingnumero?:string;
    numeroctn: string;
    gaudi_registrado?: boolean;
    tipo: string;
  
  } 
  
export interface Usuario extends Entity{
   doc_id?: string,
   empresas?:  Array<any>,
   modulesMenu?:  Array<any>,
   email:String,
   tipo:String
} 

export interface UserProfile extends Entity {
  uid: string;
  email: string;
  displayName: string;
  photoURL: string;
  phoneNumber: string;
}

export interface EventoPesada extends Entity {
  id_pesada:string,
  fecha:string,
  boleta:string,
  neto:string,
  tara:string,
  bruto:string,
  idreserva:string,
  idcliente:string,
  numexterno:string,
  id:string,
  itemadded:string,
  itemfecha:string,
  itemnombre:string,
  meta:{pesada:string, cantidad:string, d1:number, d2:number, d3:number, d4:number, lote:string};
  empresa:string,
  nombreproducto:string,
} 

  
  export interface Evento extends Entity {
    id: string;
    added: string;
    registroMedicion?:string;
    fecha: string;
    fechasalida: string;
    idcamion: string;
    idcliente: string;
    numerosms: string;
    tipo: string;
    idproducto: string;
    booking: string;
    numeroctn: string;
    fechahora_pesada: any;
    consolidado: string;
    fumigado: string;
    precinto: string ;
    tara: string ;
    payload: string;
    remito: string;
    idcamionsalida: string;
    bultos: any;
    itemadded: string;
     itemfecha: string;
     itemnombre: string ;
     itemvalor: string;
     meta:{pesada:string, cantidad:string, d1:number, d2:number, d3:number, d4:number, lote:string};
  } 
  
  export interface EventoPost extends Entity {
    nombreevento:string;
    fecha:string;
    contenedor:any;
    lote:string  ;
    meta:any  ;
    numero?:string;
    tokennot?:string;
  } 

   
  export interface Lugar extends Entity {
    nombre:string;
  } 

  export interface Empresa extends Entity{
    nombre:string;
     nombresistema:string;
  } 
  export interface Booking {
    id: string;
    added: string;
    numero: string;
    tipo: string;
    multimodal: string;
    anulada: string;
    diaspuerto: string;
    fechainicio: string;
    fechafin: string;
    idcliente: string;
    idcliente_flete: string;
    idcliente_consignatario: string;
    idproducto: string;
    stock_externo: string;
    tipoctn: string;
    diasctn: string;
    fechacutoff: string;
    fechacutoff_doc: string;
    fechastacking: string;
    cantidadctn: string;
    consolidar: string;
    fumigar: string;
    linea: string;
    destino: string;
    envase: string;
    idlinea: string;
    idport: string;
    idterminal: string;
    buque: string;
    proforma: string;
    inspeccion: string;
    bultos:string;
    variedadproducto: string;
    descr: string;
    fumigado_json: string;
    nombrecliente: string;
    nombrecliente_allin: string;
    nombrecliente_flete: string;
    nombreproducto: string;
    "consolidado-cant-items": string;
    //origen-destino: string;
   "consolidado-cant-ctns": string;
   'consolidado-precinto':string;
  } 
  
  export interface Pesadas{
    GRMovId:	string;
    GRMovDoc:	string;
    PrdId:	string;
    SKDepId:	string;
    SKTId:	string;
    GRMovHum:	string;
    GRMovPH:	string;
    GRMovBrt:	string;
    GRMovTar:	string;
    GRMovNet:	string;
    GRMovSec:	string;
    GRMovES:	string;
    GRMovObs:	string;
    GRMovFch:	string;
    GRMovCnt:	string;
    MatId:	string;
    GRMovFLCrg:	string;
    GRMovDst:	string;
    GRMovFLObs:	string;
    GRMovFLImp:	string;
    GRMovFPImp:	string;
    GRMovFLMon:	string;
    FLTarId:	string;
    GRMovFLMan:	string;
    GRMovDHum:	string;
    GRMovDMan:	string;
    GRMovSal:	string;
    GRMovFchI:	string;
    GRMovHraE:	string;
    GRMovHraS:	string;
    GRMovUsrI:	string;
    GRMovWstI:	string;
    GRMovBrtA:	string;
    GRMovPrl:	string;
    GRMovTarA:	string;
    GRMovDtCal:	string;
    EmpChId:	string;
    GRDstId:	string;
    GRMovOC:	string;
    EmpId:	string;
    GRMovOE:	string;
    GRMovEmpDs:	string;
    GRMovPrdDs:	string;
    GRMovImp:	string;
    GRMovTir:	string;
    GRMovDocEx:	string;
    GRMovPrn:	string;
    GRMovEst:	string;
    GRMovBrtE:	string;
    GRMovTarE:	string;
    GRMovHumE:	string;
    GRMovPHE:	string;
    SMLotId:	string;
    GRMovFLPMo:	string;
    GRMovNeg:	string;
    GRMovFPPIm:	string;
    GRMovLotId:	string;
    GRMovKgTpo:	string;
    GRMovKgSuc:	string;
    GRMovKgPri:	string;
    GRMovKgSg1:	string;
    GRMovKgSg2:	string;
    GRMovKgSub:	string;
    GRMovAPrl:	string;
    GRMovObs2:	string;
    GRMovFLRet:	string;
    GRMovFLEmp:	string;
    GRMovVlc:	string;
    GRMovASec:	string;
    GRMovNetO:	string;
    GRLugId:	string;
    EnvaseId:	string;
    GRMovCodigoBarras:	string;
    GRMovBlt:	string;
    GRMovBook:	string;
    GRMovCont:	string;
    GRMovCE:	string;
    GRMovMdf:	string;
    GRMovContTar:	string;
    GRDstDsc:	string;
    EnvaseDescripcion:	string;
    SKTDsc:	string;
    SKTES:	string;
    EmpChDsc:	string;
    calidad_humedad:	string;
    calidad_peso_hect:	string;
    calidad_cuerpos_ext:	string;
    calidad_qubrados:	string;
    calidad_danio_verdes:	string;
    calidad_danio_brotados:	string;
    calidad_danio_fusarium:	string;
    calidad_danio_carbon:	string;
    calidad_danio_verdin:	string;
    calidad_danio_fermentado:	string;
    calidad_danio_ardido:	string;
    calidad_danio_danio_calor:	string;
    calidad_danio_otros_danios:	string;
    calidad_danio_picado:	string;
    calidad_danio_amohosado:	string;
    calidad_da:	string;
    calidad_danio_total:	string;
    calidad_calcinado:	string;
    calidad_secado:	string;
    GRLugDsc:	string;
    SKDepDsc:	string;
    descarga?:string;
    tipomov?:string;
    carga?:string;
  } 

  export interface PesadasGaudi{
    id:string;
    added:string;
    importado:string;
    fecha:string;
    entrada:string;
    salida:string;
    boleta:string;
    numexterno:string;
    idcliente:string;
    idsegmento:string;
    idcamion:string;
    idfletero:string;
    idreserva:string;
    idtarifa:string;
    idfactura:string;
    neto:string;
    netoorigen:string;
    tara:string;
    bruto:string;
    humedad:string;
    ph:string;
    descuento_manipuleo:string;
    subtotal:string;
    cantunidades:string;
    es:string;
    costo_secado:string;
    prelimpieza:string;
    ajustecalidad:string;
    observaciones:string;
    esvolcadora:string;
    costo_entrada:string;
    costo_prelimpieza:string;
    costo_descarga:string;
    descuento_humedad:string;
    costo_salida:string;
    origenimportacion:string;
    lugar_descarga:string;
    secado:string;
    calidad_meta:string;
    empresa:string;
    nombreproducto:string;
    reservamovimiento:string;
    matricula:string;
    empresafletero:string;
    fechasemana:string;
    fechames:string;
    
  } 
  export interface EventoOperativa extends Entity {
   
        descEvento: string;
        valorEvento:string;
        tipovalorEvento:string;
        estadoEvento:string;
        doc_id?: string;
        expanded?: boolean;
        subeventos?:  Array<any>;
        timestamp?:String   ;
      tokennot?:string     
 
  }
  export interface Empresas {  
  
   nombre:string;
   nombresistema:string;

  }

  export interface imagenContenedor extends Entity{  
    titulo: string,
    contenedor?:  Array<any>,
    downloadURL: "",
    path:string,
    comentarios: string 
    agregado?:Timestamp,
    did?:string
   }

   export interface tipoeventoctn extends Entity{  
    nombre: string,
    valor?:  Array<any>,
   }