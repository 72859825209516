import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'disconnected',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginPageModule'
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home/home.module').then(m => m.HomePageModule)
      }
    ]
  },
  {
    path: 'verctn',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/ctndashboard/ctndashboard.module').then(m => m.CtndashboardPageModule),
      }
    ]
  },
  {
    path: 'verlistctn/:objeto/:tipo',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/contenedores-list/contenedores-list.module').then(m => m.ContenedoresListPageModule),
      }
    ]
  },
  {
    path: 'verlistctn',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/contenedores-list/contenedores-list.module').then(m => m.ContenedoresListPageModule),
      }
    ]
  }, {
    path: 'dashboard',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(m => m.DashboardPageModule)
      }
    ]
  },
  {
    path: 'empresa',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/empresas-list/empresas-list.module').then(m => m.EmpresasListPageModule)
      }
    ]
  },
  {
    path: 'booking',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/booking-list/booking-list.module').then(m => m.BookingListPageModule)
      }
    ]
  }, {
    path: 'pesadaslist',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/pesadaslist/pesadaslist.module').then(m => m.PesadaslistPageModule)
      }
    ]
  },
  {
    path: 'pesadadetalle', loadChildren: './pages/pesadadetalle/pesadadetalle.module#PesadadetallePageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'listado-registros', loadChildren: './pages/listado-registros/listado-registros.module#ListadoRegistrosPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'listado-registros/:tipo/:objeto', loadChildren: './pages/listado-registros/listado-registros.module#ListadoRegistrosPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'eventospesadaslist', loadChildren: './pages/eventospesadaslist/eventospesadaslist.module#EventospesadaslistPageModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'disconnected',
    loadChildren: () => import('./pages/disconnected/disconnected.module').then( m => m.DisconnectedPageModule),
    canActivate: [AuthGuard]
  }







];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
