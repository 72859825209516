import { ModalController, ToastController, AlertController } from '@ionic/angular';
import { Component, OnInit, Input } from '@angular/core';
import { Pesadas } from '../../../core/models';
import { FormBuilder, Validators } from '@angular/forms';
import { Contenedor } from '../../../core/models';
import { Empresa } from '../../../core/models';
//import { DataProvider } from '../../services/data-service';
import * as moment from 'moment';

@Component({
  selector: 'app-modaltrasbordo',
  templateUrl: './modaltrasbordo.page.html',
  styleUrls: ['./modaltrasbordo.page.scss'],
})
export class ModaltrasbordoPage implements OnInit {

  @Input() listaPesadas: Pesadas[];
  @Input() contenedor: Contenedor;
  @Input() empresa: Empresa;

  selectedPesadas: Pesadas;
  filteredPesadas: Pesadas[] = [];
  selectedPesadatrue=false;
  
  public asignarentrada;
  pesadas: any []=[];
  //contenedor: any;
  claseant: any;
  numeroctn: "";
  //empresa:"";
  empresObj: any;

      
  constructor(
    private modalController: ModalController,
    private toastrController: ToastController,
    public formBuilder: FormBuilder,
    public alertCtrl: AlertController) {
      //console.log(this.empresa)
      
   this.asignarentrada = formBuilder.group({
        cantidad: ['', Validators.compose([Validators.minLength(1), Validators.required])],
        pesada: ['', Validators.compose([Validators.minLength(3), Validators.required])]
    })
  }

  ionViewDidLoad() {
    //console.log('ionViewDidLoad ModalPage');
  }

  ngOnInit() {
    this.filteredPesadas = this.listaPesadas
    ////console.log(this.filteredRemolques)
  }

  selectPesada(p: Pesadas) {
    this.selectedPesadas = p;
    this.selectedPesadatrue=true;
  }

  guardarRegistro() {    
    if (this.asignarentrada.valid) {
      let date =new Date().toISOString();
      let metadata = {
        pesadas: this.asignarentrada.value.pesada,
        cantidad: this.asignarentrada.value.cantidad
      };
      //console.log(metadata)
      let json = JSON.stringify(metadata);
  
      this.modalController.dismiss({
        fecha: date,
        meta: json,
        lote: this.asignarentrada.value.pesada,
        nombreevento:  "consolidado-item-trasbordo",
        selectedPesada: this.asignarentrada.value.pesada
      }); 
    }
  }

  

  acceptPesada() {
    if (this.asignarentrada.valid) {
      this.guardarRegistro();
    } else {
      this.toastrController.create({
        message: 'Debe seleccionar una pesada para continuar',
        duration: 2000,
        color: 'warning',
        cssClass: 'toast-container'
      }).then(toast => {
        toast.present();
      });
    }
  }

  closeModal() {  
      //console.log('Lo cierro!')  
     this.modalController.dismiss({
      }); 

  }

}
