import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastFactoryService {

    constructor(public toastCtrl: ToastController) {
    }

    async error(message: string, duration?: number): Promise<any> {
        return this.toastCtrl.create({
            message,
            duration: duration || 3500,
            position: 'top',
            cssClass: 'error-toast'
        });
    }

    async success(message: string, duration?: number): Promise<any> {
        return this.toastCtrl.create({
            message,
            cssClass: 'success-toast',
            duration: duration || 3500,
            position: 'top'
        });
    }

    async warning(message: string, duration?: number): Promise<any> {
        return this.toastCtrl.create({
            message,
            cssClass: 'warning-toast',
            duration: duration || 3500,
            position: 'top'
        });
    }
}
