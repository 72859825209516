import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { PesadasGaudi } from '../../../core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiserviceService } from '../../services/apiservice.service';
import { ApiService } from '../../services/api.service';
import { ToastService } from '../../utilities';
import { Observable } from 'rxjs';
import { LoadingController, IonInput } from '@ionic/angular';
import {
  BarcodeScannerOptions,
  BarcodeScanner
} from "@ionic-native/barcode-scanner/ngx";
import { ModalController, AlertController } from '@ionic/angular';

@Component({
  selector: 'app-modalregistrorolos',
  templateUrl: './modalregistrorolos.page.html',
  styleUrls: ['./modalregistrorolos.page.scss'],
})
export class ModalregistrorolosPage implements OnInit {
  @ViewChild('inputd1', { static: false }) inputd1:IonInput;
  @Input() pesada: PesadasGaudi
  @Input() uetiqueta: any;
  @Input() uereg: any;

  eventosRegistrados: any[];
  scanData: {};
  options: BarcodeScannerOptions;
  registroForm: FormGroup;
  public boletaexiste = "";

  public existe = null;
  eventos: any[] = [];


  constructor(
    public barcodeScanner: BarcodeScanner,
    private modalController: ModalController,
    public apiservice: ApiserviceService,
    private toast: ToastService,
    private api: ApiService,
    private loadingController: LoadingController,
    private alert: AlertController,
    private formBuilder: FormBuilder) {

    this.registroForm = this.formBuilder.group({
      etiqueta: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      d1: ['', Validators.compose([Validators.required, Validators.maxLength(4),
      Validators.min(10), Validators.max(200), Validators.minLength(2)])],
      d2: ['', Validators.compose([Validators.required, Validators.maxLength(4),
      Validators.min(10), Validators.max(200), Validators.minLength(2)])],
      d3: ['', Validators.compose([Validators.required, Validators.maxLength(4),
      Validators.min(10), Validators.max(200), Validators.minLength(2)])],
      d4: ['', Validators.compose([Validators.required, Validators.maxLength(4),
      Validators.min(10), Validators.max(200), Validators.minLength(2)])]

    });

  }

  ngOnInit() {
    //console.log(this.uetiqueta);
    if (this.uetiqueta) {
      this.registroForm.controls['etiqueta'].setValue(this.uetiqueta);
    }
    this.cargaEventos();
    this.cargarEventosPesadas()
  }




  scan() {
    this.options = {
      torchOn: false,
      showTorchButton: true
    };
    /*this.logscan.fecha=this.timeNow;
    this.logscan.contenedor=this.contenedor;
    this.logscan.lote="barcodeData"+"";*/
    //this.authData.addItem("logscan", logscan);

    this.barcodeScanner.scan(this.options).then((barcodeData) => {


      this.scanData = barcodeData.text;
      //console.log(this.scanData)

      this.registroForm.controls['etiqueta'].setValue(this.scanData);

      this.existeLote2(this.scanData + "");
      // this.authData.addItem("logregscanlote",logscan);
    }, (err) => {
      //console.log("Error occured : " + err);
    });

  }

  async registrarEnFirestore(): Promise<void> {
    if (this.registroForm.valid) {
      const el = this.existeLote(this.registroForm.value.etiqueta);
      if (el) {
        const date = new Date().toISOString();
        const metadata = {
          pesada: this.pesada.boleta,
          d1: this.registroForm.value.d1,
          d2: this.registroForm.value.d2,
          d3: this.registroForm.value.d3,
          d4: this.registroForm.value.d4,
          lote: this.registroForm.value.etiqueta.toUpperCase()
        };
        const json = JSON.stringify(metadata);
        const registro = {
          nombreevento: 'medicion-item',
          fecha: date,
          numero: this.pesada.boleta,
          lote: this.registroForm.value.etiqueta.toUpperCase(),
          meta: json
        };
        var lote = this.registroForm.value.etiqueta.toUpperCase();
        this.modalController.dismiss({
          reistro: registro,
          lote: lote
        });
      } else {
        await (await this.toast.error(' No registrado! <br> Etiqueta ya existe <br> ' + this.boletaexiste)).present();

      }
    }
  }

  async registrar() {
    if (this.registroForm.valid) {
      const el = this.existeLote(this.registroForm.value.etiqueta);
      console.log(el);
      if (el) {
        let date = new Date().toISOString();
        let metadata = {
          pesada: this.pesada.boleta,
          d1: this.registroForm.value.d1,
          d2: this.registroForm.value.d2,
          d3: this.registroForm.value.d3,
          d4: this.registroForm.value.d4,
          lote: this.registroForm.value.etiqueta.toUpperCase()
        };
        let json = JSON.stringify(metadata);
        let registro = {
          nombreevento: "medicion-item",
          fecha: date,
          numero: this.pesada.boleta,
          lote: this.registroForm.value.etiqueta.toUpperCase(),
          meta: json
        };
        this.guardarRegistro(registro);
      } else {
        await (await this.toast.error(' No registrado! <br> Etiqueta ya existe <br> ' + this.boletaexiste)).present();
      }
    } else {
      await (await this.toast.error(' Error datos del formulario')).present();

    }
  }

  closeModal() {
    this.modalController.dismiss({
    });
  }

  cargaEventos() {
    this.apiservice.getEventosPesadas().subscribe(data => {
      if (data) {
        this.eventos = [];
        for (let i in data) {
          this.eventos.push(data[i])
        }
      }
    });

  }

  cargarEventosPesadas(): void {
    this.api.getEventosPesadas(this.pesada.boleta).subscribe(result => {
      this.eventosRegistrados = [];
      if (result && result.length > 0) {
        for (const pesada of result) {
          pesada.meta = JSON.parse(pesada.meta);
          this.eventosRegistrados.push(pesada);
        }
        this.eventosRegistrados = this.eventosRegistrados.filter(e => +e.numero === +this.pesada.boleta);
      }
    });
  }

  existeLote(lote: string): boolean {
    for (let i in this.eventos) {
      if (this.eventos[i].meta.lote.toUpperCase() === lote.toUpperCase()) {
        this.boletaexiste = "Pesada: " + this.eventos[i].boleta + " Remito: " + this.eventos[i].numexterno;
        this.existe = this.eventos[i];
      }
    }
    if (this.existe == null) {
      return true;
    } else {
      this.existe = null
      this.boletaexiste = "";
      return false;
    }
    //console.log(this.boletaexiste);
  }

  existeLote2(lote: string) {
    //console.log(lote);
    this.existe = null
    this.boletaexiste = "";
    for (let i in this.eventos) {
      if (this.eventos[i].meta.lote.toUpperCase() === lote.toUpperCase()) {
        this.boletaexiste = "Pesada: " + this.eventos[i].boleta + " Remito: " + this.eventos[i].numexterno;
        this.existe = this.eventos[i];
      }
    }
    for (let i in this.eventosRegistrados) {
      if (this.eventosRegistrados[i].lote.toUpperCase() === lote.toUpperCase()) {
        this.boletaexiste = "Pesada: " + this.pesada.boleta + "!";
        this.existe = this.eventosRegistrados[i];
      }
    }
    if (this.existe === null) {
      this.existe = null
      this.boletaexiste = "";
    }
    //console.log(this.boletaexiste);
  }


  async guardarRegistro(data) {
    if (data) {
      const loading = await this.loadingController.create();
      await loading.present();
      const registro = data;
      console.log(registro["lote"])
      this.uetiqueta = registro["lote"];

      this.guardarPesada(registro).subscribe(async result => {
        if (result) {
          loading.dismiss();
          const toast = await this.toast
            .success('Rolo Registrado');
          toast.present();
          const numero = parseInt(this.uetiqueta.slice(-5)) + 1;
          const etiqueta = this.uetiqueta.slice(0, -5) + numero;
          this.limpiarForm(etiqueta);
        }
      }, async err => {
        loading.dismiss();
        const toast = await this.toast
          .error('No Registrado');
        toast.present();
      });
    }
  }

  guardarPesada(pesada: any): Observable<any> {
    return this.api.guardarEventoPesada(pesada);
  }

  limpiarForm(etiqueta: any) {
    this.registroForm.controls['etiqueta'].setValue(etiqueta);
    this.registroForm.controls['d1'].setValue("");
    this.registroForm.controls['d2'].setValue("");
    this.registroForm.controls['d3'].setValue("");
    this.registroForm.controls['d4'].setValue("");
    this.existeLote2(etiqueta);
    setTimeout(() => {
      this.inputd1.setFocus();
    }, 150);
  }

  async eliminarlote(lote: any) {

    const alert = await this.alert.create({
      header: 'Eliminar Lote?',
      message: '' + lote.meta.lote,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          handler: data => {
            //console.log('Cancel clicked');
          }
        },
        {
          text: 'Eliminar',
          handler: () => {
            const i = this.eventos.indexOf(lote);
            this.eventos.splice(i, 1);
            this.api.borrarEventoPesada(lote, i).subscribe(async data => {
              const toast = await this.toast
                .success('Eliminado');
              toast.present();
              this.cargaEventos();
            }, err => {
              //console.log(err);
            });
          }
        }
      ]
    });
    await alert.present();
  }

}
