import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Network } from '@ionic-native/network/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouteReuseStrategy } from '@angular/router';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { HttpConfigInterceptor } from './shared/services/httpconfig.intercept';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { DatePipe } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
//import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { IonicStorageModule } from '@ionic/storage';
import {LocalStorageService} from './core/api/local-storage.service';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ComponentsModule } from './shared/components/components.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ErrorMessages } from './core/error';
import '@ionic/pwa-elements';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FileService } from './shared/services/file.service'; 

import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';

@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        CoreModule,
        SharedModule,
        HttpClientModule,
        ComponentsModule,
        NgxIonicImageViewerModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        IonicStorageModule.forRoot()        
    ],
    providers: [
        AngularFirestore,
        StatusBar,
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
        //   FcmService,
        BarcodeScanner,
        SplashScreen,
        FileService,
        LocalStorageService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy, },
        Network
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    static injector: Injector;

    constructor(injector: Injector) {
        AppModule.injector = injector;
    }

    /*{
        provide: HTTP_INTERCEPTORS,
        useClass: HttpConfigInterceptor,
        multi: true
      }*/
}
