import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ToastFactoryService } from './utils/toast-factory.service';
import { ToastService } from './utilities/toast.service';
import { AlertFactoryService } from './utils/alert-factory.service';
//import { PageHeaderComponent } from './components/page-header/page-header';
import { FilterBarComponent } from './components/filter-bar/filter-bar.component';
import { AppLoadingController } from './utils/app-loading.controller';
import { PopoverProgressComponent } from './components/popover-progress/popover-progress.component';
import { AuthGuard } from './guards/auth.guard';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MultipleFormImageComponent } from './components';
import { Timeout } from './decorators';



@NgModule({
  declarations: [ FilterBarComponent, PopoverProgressComponent, MultipleFormImageComponent],
  imports: [IonicModule, CommonModule, FormsModule, ReactiveFormsModule],
  providers: [ ToastFactoryService, AlertFactoryService, AppLoadingController, AuthGuard, ToastService],
  exports: [ FilterBarComponent, PopoverProgressComponent, MultipleFormImageComponent],schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class SharedModule {
}
