import { Component, OnInit, Input } from '@angular/core';
import { PesadasGaudi, Pesadas } from '../../../core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiserviceService } from '../../services/apiservice.service';
import { EmpresasService } from '../../services/empresas.service';
import { DIAS_CONTENEDOR, DIAS_BOOKING, CURRENT_PESADAS, DEFAULT_EMPRESA } from '../../constants/local-storage';
import { CURRENT_CTN, TOKEN_NOTIFICATION } from '../../../shared/constants/local-storage';
import { ModalController, ToastController } from '@ionic/angular';
import * as moment from 'moment';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { ToastService } from '../../services/toast.service';

@Component({
  selector: 'app-modalregistcoment',
  templateUrl: './modalregistcoment.page.html',
  styleUrls: ['./modalregistcoment.page.scss'],
})
export class ModalregistcomentPage implements OnInit {
  @Input() referencia: String
  @Input() tiporeferencia: String;
  registroForm: FormGroup;
  empresas: Array<{ nombre: string, nombresistema: string }>;
  tokenlocals: Array<{ id: string, colleccion: string }>;
  empresaslist: any;
  notification: boolean = false;
  tokenls = "";
  temas = ["Información", "Consolidado", "Desconsolidado", "Precinto", "Bultos", "Payload", "Trasbordo", "Descarga", "Estado", "Reclamo", "Kilos", "Numero", "Carga", "Otro"];

  constructor(
    private toast: ToastService,
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    public apiservice: ApiserviceService,
    public empservice: EmpresasService,
    private db: AngularFirestore) {


    this.registroForm = this.formBuilder.group({
      // empdefaultpesadas: ['', Validators.compose([Validators.minLength(5)])],
      tema: ['', Validators.compose([Validators.required])],
      comentarios: ['', Validators.compose([Validators.required])],

    });
  }

  ngOnInit() {
  }

  async registrar() {
    if (this.registroForm.valid) {
      const tema = this.registroForm.value.tema;
      const comentarios = this.registroForm.value.comentarios;
      let date = new Date().toISOString();
      let registro = {
        tema: tema,
        fecha: date,
        comentarios: comentarios,
        referencia: this.referencia,
        tiporeferencia: this.tiporeferencia
      };
      this.db.collection("comentarios").add(registro).then( async data => {
        if (tema === "Precinto") {
          this.postEventos(comentarios, "consolidado-precinto")
        }
        if (tema === "Bultos") {
          this.postEventos(comentarios, "consolidado-bultos")
        }
        
      await (await this.toast.success("Registrado!", 1500)).present();

      });
      this.modalController.dismiss();
    } else {
   
      await (await this.toast.error("No registrado")).present();
    }

  }

  async postEventos(lote, tipo) {
    let fecha = new Date().toISOString();
    //let fecha = date.getFullYear().toString()+"-"+(date.getMonth()+1).toString()+"-"+date.getDate().toString()+" "+date.getHours().toString()+":"+date.getMinutes().toString()+":"+date.getSeconds().toString();
    let logscan = { nombreevento: tipo, fecha: fecha, contenedor: this.referencia, lote: lote + "", meta: "" };
    //console.log(logscan);

    this.apiservice.postEvento(logscan).subscribe(async data => {

      //console.log(data);
    },async err => {

     
      await (await this.toast.error("No registrado")).present();
    });
  }

  closeModal() {
    this.modalController.dismiss({
    });
  }


}
