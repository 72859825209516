import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { RestService } from '../../core/api/rest.service';
import { Lugar } from '../../core/models';
import { ErrorMessages } from '../../core/error';

import { Logger } from '../../core/log';

@Injectable({
    providedIn: 'root'
})
export class LugarService extends RestService<Lugar> {
    constructor(
        protected errorMessages: ErrorMessages,
        protected afAuth: AngularFireAuth,
        protected store: AngularFirestore,
        protected logger: Logger
    ) {
        super(errorMessages, afAuth, store, logger, 'lugaresdescarga');
    }
}
