import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';
import { datePickerToMoment, isDatePickerObject } from '../utils/utils';

export class CustomValidators {

    private static readonly EMAIL_VALIDATOR_LOCAL_ADDR: RegExp =
        /^[a-z0-9.!#$%&'*+\/=?^_`{|}~-]+$/i;
    private static readonly EMAIL_VALIDATOR_DOMAIN: RegExp =
        /^[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?(?:\.[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?)+$/i;

    static email(control: AbstractControl): ValidationErrors | null {
        return CustomValidators.isEmail(control.value) ? undefined : {email: true};
    }

    protected static isEmail(str: string): boolean {
        if (!str) {
            return false;
        }
        const parts = str.split('@');
        if (parts.length !== 2) {
            return false;
        }
        if (!this.EMAIL_VALIDATOR_LOCAL_ADDR.test(parts[0])) {
            return false;
        }

        return this.EMAIL_VALIDATOR_DOMAIN.test(parts[1]);
    }

    /**
     * Si un valor esta definido, como consecuencia el siguiente debe estarlo tambien. Esto es util para casos cuando es necesario
     * que dos valores esten presentes en un formulario si por lo menos uno de ellos esta definido.
     * @param field1 - key del atributo del form
     * @param field2 - key del atributo del form
     * @param validatorField - field error a retornar con el key
     */
    static oneDefinedBothRequired(field1: any, field2: any, validatorField: { [key: string]: boolean }): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            const value1 = c.get(field1).value;
            const value2 = c.get(field2).value;
            if (value1 || value2) {
                if (!value2 || !value2) {
                    return validatorField;
                }
            }

            return undefined;
        };
    }

}

export class DateValidators {

    /**
     * Evalua si la primera fecha es menor a la segunda.
     * @param dateField1 - primera fecha
     * @param dateField2 - segunda fecha
     * @param format - formato de la fecha (opcional)
     * @param validatorField - ValidatorField a retornar
     */
    static dateLessThan(dateField1: string,
                        dateField2: string,
                        validatorField: { [key: string]: boolean },
                        format?: string): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (!format) {
                format = 'YYYY-MM-DD';
            }
            const originalValue1 = c.get(dateField1).value;
            const originalValue2 = c.get(dateField2).value;
            if (!originalValue1 || !originalValue2) {
                return;
            }
            const date1 = isDatePickerObject(originalValue1) ? datePickerToMoment(originalValue1) : moment(originalValue1, format);
            const date2 = isDatePickerObject(originalValue2) ? datePickerToMoment(originalValue2) : moment(originalValue2, format);
            if ((date1 !== null && date2 !== null) && !date1.isBefore(date2) && !date1.isSame(date2)) {
                return validatorField;
            }

            return undefined;
        };

    }

    /**
     * Evalua si hay un periodo de 'n' dias entre la primera fecha y la segunda fecha.
     * @param  dateField1 - primera fecha
     * @param  dateField2 - segunda fecha
     * @param  days - numero de dias
     * @param validatorField - ValidatorField a retornar
     */
    static dateInRangeOf(dateField1: string,
                         dateField2: string,
                         days: number,
                         validatorField: { [key: string]: boolean },
                         format?: string): ValidatorFn {
        return (c: AbstractControl): { [key: string]: boolean } | null => {
            if (!format) {
                format = 'YYYY-MM-DD';
            }
            const originalValue1 = c.get(dateField1).value;
            const originalValue2 = c.get(dateField2).value;
            if (originalValue1 && originalValue2) {
                const date1 = isDatePickerObject(originalValue1) ? datePickerToMoment(originalValue1) : moment(originalValue1, format);
                const date2 = isDatePickerObject(originalValue2) ? datePickerToMoment(originalValue2) : moment(originalValue2, format);
                const daysBetween = date1.diff(date2, 'days');
                if (Math.abs(daysBetween) > days) {
                    return validatorField;
                }
            }

            return undefined;
        };
    }

}