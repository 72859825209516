import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable()
export class LocalStorageService {
    constructor(
      private storage: Storage
    ) {}

    getItem(key: string) {
        return this.storage.get(key);
    }

    setItem(key: string, value: any) {
        this.storage.set(key, value);
    }

    keyIsNotNull(key: string): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.storage.get(key)
                .then(response => {
                    resolve(response !== null);
                }).catch(error => {
                    reject(false);
                });
        });
    }

    nullify(key: string) {
        this.setItem(key, null);
    }
}